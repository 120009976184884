import React from "react";
import CarouselSlider from "./components/CarouselSlider";
import Image from "react-bootstrap/Image";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAuthContext } from "./components/AuthContext";
import Towns from "../src/images/SmithtownIslipTowns.png";
import { BsCalendar3 } from "react-icons/bs";

const styles = {
  icon22: { fontSize: 22, marginLeft: 4 },
};

function App() {
  const { currentUser } = useAuthContext();
  return (
    <Container>
      <div className="d-flex justify-content-center">
        {currentUser ? (
          <Link
            to="/home"
            className="btn btn-warning mx-auto fw-bold my-2 shadow-lg d-flex align-items-center justify-content-center"
            role="button"
          >
            Make an Appointment Now <BsCalendar3 style={styles.icon22} />
          </Link>
        ) : (
          <Link
            to="/login"
            className="btn btn-warning mx-auto fw-bold my-2 shadow-lg d-flex align-items-center justify-content-center"
            role="button"
          >
            Make an Appointment Now <BsCalendar3 style={styles.icon22} />
          </Link>
        )}
      </div>
      <CarouselSlider />

      <Container className="my-2">
        <Image src={Towns} fluid  alt="Long Island service area towns."/>
      </Container>
    </Container>
  );
}

export default App;
