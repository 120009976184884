import React, { useState } from "react";
import { Alert, Button, ButtonGroup, ToggleButton } from "react-bootstrap";
import { useAuthContext } from "./AuthContext";
import { TimeFind } from "../functions/TimeFind";
import { Container } from "react-bootstrap";
import { SetAppointment } from "../functions/SetAppointment";
import { AiOutlineClockCircle } from "react-icons/ai";
import { TbCheckbox } from "react-icons/tb";
import { FaPerson } from "react-icons/fa6";
import { MdOutlineSupportAgent } from "react-icons/md";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import logError from "../functions/logError";
import ErrorNotification from "./ErrorNotification";
import InitialDescription from "./InitialDescription";

interface timesProps {
  showTime: boolean;
  hourChange: number;
  date: any;
  setShowTime: any;
}

const renderTooltip = (props: any) => (
  <Tooltip id="button-tooltip" {...props}>
    Please choose a Visit Type!
  </Tooltip>
);

const styles = {
  icon20: {
    fontSize: 20,
    marginLeft: 2,
    pointerEvents: "none" as React.CSSProperties["pointerEvents"],
  },
  icon22: {
    fontSize: 22,
    marginLeft: 1,
    zIndex: 0,
    minWidth: "30px",
  },
};

const TIME_LENGTH = 5000;

export default function Times({
  showTime,
  hourChange,
  date,
  setShowTime,
}: timesProps) {
  // To update one part of the state object, we need to use the spread operator like setEventInfo({ ...eventInfo, info: true })
  const [eventInfo, setEventInfo] = useState({ event: "", info: false });
  const [isRemote, setIsRemote] = useState(false);
  const [appointments, setAppointments] = useState<string>();
  // const [loadData, setLoadData] = useState<any | null>(loader);
  const [errorMessage, setErrorMessage] = useState<string | null>(null); // This will be used to display error messages to the User
  const { currentUser, scheduleData } = useAuthContext();
  const [modalShow, setModalShow] = useState({// This is used for the DescriptionModal component
    modalShow: false,
    appointmentId: "",
    description: "",
  });

  const timeFind = TimeFind(scheduleData, date);
  const userId = currentUser.uid;

  const formattedDate =
    date.getFullYear() * 1e4 +
    (date.getMonth() + 1) * 100 +
    date.getDate() +
    "";

  function displayInfo(e: any) {
    setEventInfo({ event: e.target.innerText, info: true });
    e.target.style.backgroundColor = "green";
    const timestamp = `${date.toDateString()} ${e.target.value}`;
    setAppointments(timestamp);
  }

  const time = [
    { id: 1, localTime: "08:00am", utcTime: `${8 + hourChange}:00` },
    { id: 2, localTime: "09:30am", utcTime: `${9 + hourChange}:30` },
    { id: 3, localTime: "11:00am", utcTime: `${11 + hourChange}:00` },
    { id: 4, localTime: "12:30pm", utcTime: `${12 + hourChange}:30` },
    { id: 5, localTime: "02:00pm", utcTime: `${14 + hourChange}:00` },
    { id: 6, localTime: "03:30pm", utcTime: `${15 + hourChange}:30` },
    { id: 7, localTime: "05:00pm", utcTime: `${17 + hourChange}:00` },
    { id: 8, localTime: "06:30pm", utcTime: `${18 + hourChange}:30` },
  ];

  async function buttonHandler(e: any) {
    e.preventDefault();
    let visit: string = isRemote ? "Remote Support" : "In-Person";
    if (window.confirm(`Are you sure you want a ${visit} visit? If YES select OK`) === true) {
      const { success, appointId } = SetAppointment(
        appointments,
        userId,
        formattedDate,
        eventInfo.event,
        currentUser.email,
        isRemote
      );
      if (success) {
        try {
          setEventInfo({ event: "", info: false });

          setShowTime(() => false);

          setModalShow({
            modalShow: true,
            appointmentId: appointId,
            description: "",
          });
        } catch (error: any) {
          logError(
            "Admin",
            `Error: ${error.name}, Message: ${error.message}, Stack: ${error.stack}`,
            "AppointmentSet function failed",
            "Times.tsx"
          ).catch((loggingError) => {
            // this is the catch block for the logError function which is a promise
            console.error("Error occurred while logging the error.");
          });
          setErrorMessage(
            "Error, your appointment was not set. An admin has been notified."
          );
        }
      }
      setIsRemote(false);
    } else {
      return;
    }
  }

  function getArray() {
    const timeArray: any[] = time.map((item) => {
      if (timeFind.includes(item.localTime)) {
        return {
          localTime: item.localTime,
          utcTime: item.utcTime,
          disabled: true,
        };
      } else {
        return {
          localTime: item.localTime,
          utcTime: item.utcTime,
          disabled: false,
        };
      }
    });
    return (
      <Container>
        {errorMessage ? (
          <ErrorNotification
            message={errorMessage}
            timeLength={TIME_LENGTH}
            setErrorMessage={setErrorMessage}
          />
        ) : null}
        <div className="row">
          <Button
            variant="primary"
            size="sm"
            //style={styles.button}
            onClick={(e) => displayInfo(e)}
            value={timeArray[0].utcTime}
            className="col me-1 mb-1 d-flex align-items-center justify-content-center"
            disabled={timeArray[0].disabled}
          >
            {timeArray[0].localTime}
            <AiOutlineClockCircle style={styles.icon20} />
          </Button>
          <Button
            variant="primary"
            size="sm"
            //style={styles.button}
            onClick={(e) => displayInfo(e)}
            value={timeArray[1].utcTime}
            className="col me-1 mb-1 d-flex align-items-center justify-content-center"
            disabled={timeArray[1].disabled}
          >
            {timeArray[1].localTime}
            <AiOutlineClockCircle style={styles.icon20} />
          </Button>
          <Button
            variant="primary"
            size="sm"
            //style={styles.button}
            onClick={(e) => displayInfo(e)}
            value={timeArray[2].utcTime}
            className="col me-1 mb-1 d-flex align-items-center justify-content-center"
            disabled={timeArray[2].disabled}
          >
            {timeArray[2].localTime}
            <AiOutlineClockCircle style={styles.icon20} />
          </Button>
          <Button
            variant="primary"
            size="sm"
            //style={styles.button}
            onClick={(e) => displayInfo(e)}
            value={timeArray[3].utcTime}
            className="col me-1 mb-1 d-flex align-items-center justify-content-center"
            disabled={timeArray[3].disabled}
          >
            {timeArray[3].localTime}
            <AiOutlineClockCircle style={styles.icon20} />
          </Button>

          <Button
            variant="primary"
            size="sm"
            //style={styles.button}
            onClick={(e) => displayInfo(e)}
            value={timeArray[4].utcTime}
            className="col me-1 mb-1 d-flex align-items-center justify-content-center"
            disabled={timeArray[4].disabled}
          >
            {timeArray[4].localTime}
            <AiOutlineClockCircle style={styles.icon20} />
          </Button>
          <Button
            variant="primary"
            size="sm"
            // style={styles.button}
            onClick={(e) => displayInfo(e)}
            value={timeArray[5].utcTime}
            className="col me-1 mb-1 d-flex align-items-center justify-content-center"
            disabled={timeArray[5].disabled}
          >
            {timeArray[5].localTime}
            <AiOutlineClockCircle style={styles.icon20} />
          </Button>
          <Button
            variant="primary"
            size="sm"
            //style={styles.button}
            onClick={(e) => displayInfo(e)}
            value={timeArray[6].utcTime}
            className="col me-1 mb-1 d-flex align-items-center justify-content-center"
            disabled={timeArray[6].disabled}
          >
            {timeArray[6].localTime}
            <AiOutlineClockCircle style={styles.icon20} />
          </Button>
          <Button
            variant="primary"
            size="sm"
            //style={styles.button}
            onClick={(e) => displayInfo(e)}
            value={timeArray[7].utcTime}
            className="col me-1 mb-1 d-flex align-items-center justify-content-center"
            disabled={timeArray[7].disabled}
          >
            {timeArray[7].localTime}
            <AiOutlineClockCircle style={styles.icon20} />
          </Button>
        </div>
      </Container>
    );
  }

  return (
    <Container className="mt-2">
      {showTime === true ? (
        <Container>{getArray()}</Container>
      ) : (
        <Container className="container">
          <div className="row">
            <Button
              variant="primary"
              size="sm"
              //style={styles.button}
              className="col me-1 mb-1 d-flex align-items-center justify-content-center"
            >
              08:00am <AiOutlineClockCircle style={styles.icon20} />
            </Button>
            <Button
              variant="primary"
              size="sm"
              // style={styles.button}
              className="col me-1 mb-1 d-flex align-items-center justify-content-center"
            >
              09:30am <AiOutlineClockCircle style={styles.icon20} />
            </Button>
            <Button
              variant="primary"
              size="sm"
              // style={styles.button}
              className="col me-1 mb-1 d-flex align-items-center justify-content-center"
            >
              11:00am <AiOutlineClockCircle style={styles.icon20} />
            </Button>
            <Button
              variant="primary"
              size="sm"
              //style={styles.button}
              className="col me-1 mb-1 d-flex align-items-center justify-content-center"
            >
              12:30pm <AiOutlineClockCircle style={styles.icon20} />
            </Button>
            {/* </div>
          <div className="row d-flex justify-content-evenly"> */}
            <Button
              variant="primary"
              size="sm"
              // style={styles.button}
              className="col me-1 mb-1 d-flex align-items-center justify-content-center"
            >
              02:00pm <AiOutlineClockCircle style={styles.icon20} />
            </Button>
            <Button
              variant="primary"
              size="sm"
              //style={styles.button}
              className="col me-1 mb-1 d-flex align-items-center justify-content-center"
            >
              03:30pm <AiOutlineClockCircle style={styles.icon20} />
            </Button>
            <Button
              variant="primary"
              size="sm"
              // style={styles.button}
              className="col me-1 mb-1 d-flex align-items-center justify-content-center"
            >
              05:00pm <AiOutlineClockCircle style={styles.icon20} />
            </Button>
            <Button
              variant="primary"
              size="sm"
              // style={styles.button}
              className="col me-1 mb-1 d-flex align-items-center justify-content-center"
            >
              06:30pm <AiOutlineClockCircle style={styles.icon20} />
            </Button>
          </div>
        </Container>
      )}

      {eventInfo.info ? (
        <Container>
          <div className="d-flex justify-content-center">
            <Alert variant="info" className="text-center fw-bold mb-2">
              Appointment set for {`${eventInfo.event} ${date.toDateString()}`}
            </Alert>
          </div>
          <div className="container">
            <div className="d-flex justify-content-center mb-2">
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <ButtonGroup vertical={true} size="sm" className="ms-2">
                  <ToggleButton
                    id={"radio-1"}
                    type="radio"
                    variant={"outline-info"}
                    name="radio"
                    value={"In-Person"}
                    checked={!isRemote}
                    onChange={(e) => setIsRemote(false)}
                    className="text-nowrap"
                  >
                    In-Person Visit
                  </ToggleButton>
                  <ToggleButton
                    id={"radio-2"}
                    type="radio"
                    variant={"outline-info"}
                    name="radio"
                    value={"Remote"}
                    checked={isRemote}
                    onChange={(e) => setIsRemote(true)}
                    className="text-nowrap"
                  >
                    Remote Support{" "}
                    <MdOutlineSupportAgent style={styles.icon22} />
                  </ToggleButton>
                </ButtonGroup>
              </OverlayTrigger>
            </div>
            <div className="d-flex justify-content-center mb-2">
              <Button
                variant="success"
                size="lg"
                onClick={buttonHandler}
                className="d-flex align-items-center justify-content-center"
              >
                Confirm <TbCheckbox style={styles.icon20} />
              </Button>
            </div>
          </div>
        </Container>
      ) : (
        <Container className="mb-2">
          <Alert
            variant="warning"
            className="text-center h5 mb-2 border border-danger"
          >
            Times that are shown greyed out have already been scheduled.
          </Alert>

          <div className="container">
            <div className="d-flex justify-content-center mb-2">
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <ButtonGroup vertical={true} size="lg" className="ms-2">
                  <ToggleButton
                    id={"radio-1"}
                    type="radio"
                    variant={"outline-info"}
                    name="radio"
                    value={"In-Person"}
                    checked={!isRemote}
                    onChange={(e) => setIsRemote(false)}
                    className="text-nowrap d-flex align-items-center justify-content-center"
                  >
                    In-Person Visit <FaPerson style={styles.icon22} />
                  </ToggleButton>
                  <ToggleButton
                    id={"radio-2"}
                    type="radio"
                    variant={"outline-info"}
                    name="radio"
                    value={"Remote"}
                    checked={isRemote}
                    onChange={(e) => setIsRemote(true)}
                    className="text-nowrap d-flex align-items-center justify-content-center"
                  >
                    Remote Support
                    <MdOutlineSupportAgent style={styles.icon22} />
                  </ToggleButton>
                </ButtonGroup>
              </OverlayTrigger>
            </div>
            <div className="d-flex justify-content-center mb-2">
              <Button
                variant="success"
                size="lg"
                className="text-decoration-none d-flex align-items-center justify-content-center"
                disabled
              >
                Confirm <TbCheckbox style={styles.icon20} />
              </Button>
            </div>
          </div>
        </Container>
      )}
      <InitialDescription
        modalShow={modalShow.modalShow}
        appointmentId={modalShow.appointmentId}
        description={modalShow.description}
        setModalShow={setModalShow}
      />
    </Container>
  );
}
