import React, { useReducer, useState } from "react";
import { setDoc, doc } from "firebase/firestore";
import { db } from "../firebase";
import { AiOutlineSave } from "react-icons/ai";
import logError from "../functions/logError";
import ErrorNotification from "./ErrorNotification";
import { Link } from "react-router-dom";

const initialState = {
  date: "",
  time: "",
  collectionName: "",
  context: "",
  description: "",
};

function reducer(
  state: typeof initialState,
  action: { type: string; payload?: any }
) {
  switch (action.type) {
    case "updateField":
      return { ...state, [action.payload.field]: action.payload.value };
    case "reset":
      return initialState;
    default:
      return state;
  }
}

const styles = {
  icon22: {
    fontSize: 22,
    marginLeft: 2,
  },
};

const TIME_LENGTH = 5000;

export default function ChangeLogForm() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  async function submitHandler(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const { date, time, collectionName, context, description } = state;
    if (collectionName !== "") {
      try {
        const docRef = doc(db, "config-changes", collectionName);
        const dateInfo = Date.now().toString();
        await setDoc(
          docRef,
          { [dateInfo]: { date, time, collectionName, context, description } },
          { merge: true }
        );
        dispatch({
          type: "reset",
        });
        alert("Change Log entry submitted successfully!");
      } catch (error: any) {
        logError(
          "Administrator",
          `Error: ${error.name} - ${error.message} - ${error.stack}`,
          "No appointment data",
          "ChangeLogForm.tsx"
        ).catch(() => {
          console.log("Error occurred. Please try again later.");
        });
        setErrorMessage("Error submitting form. Please try again.");
      }
    }
  }

  function handleChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) {
    dispatch({
      type: "updateField",
      payload: {
        field: event.currentTarget.name,
        value: event.currentTarget.value,
      },
    });
  }
  // name fields in firebase don't like symbols like (-) hyphen for null check
  return (
    <div className="container mb-3">
      <div className="d-flex justify-content-start mt-2">
        <Link
          to="/completed-appointments"
          className="btn btn-warning ms-2"
          role="button"
        >
          Completed Appointments
        </Link>
        <Link
          to="/paid-appointments"
          className="btn btn-success ms-2"
          role="button"
        >
          Paid Appointments
        </Link>
        <Link to="/errorlog" className="btn btn-primary ms-2" role="button">
          Error Log
        </Link>
        <Link
          to="/config-change-log"
          className="btn btn-secondary ms-2"
          role="button"
        >
          Config Log
        </Link>
      </div>
      <div className="text-center h1">Change Log Form</div>
      <div className="h6 text-center text-primary fst-italic">
        Select the category you would like to view.
      </div>
      {errorMessage ? (
        <ErrorNotification
          message={errorMessage}
          timeLength={TIME_LENGTH}
          setErrorMessage={setErrorMessage}
        />
      ) : null}
      <form className="border p-3" onSubmit={submitHandler}>
        <div className="row">
          <div className="col mb-2">
            <label htmlFor="date" className="form-label fw-bold">
              Date
            </label>
            <input
              id="date"
              type="date"
              className="form-control"
              name="date"
              placeholder="Date"
              required
              onChange={(e) => handleChange(e)}
              value={state.date}
            />
          </div>
          <div className="col mb-2">
            <label htmlFor="time" className="form-label fw-bold">
              Time
            </label>
            <input
              id="time"
              type="time"
              className="form-control"
              placeholder="Time"
              name="time"
              required
              onChange={(e) => handleChange(e)}
              value={state.time}
            />
          </div>
        </div>
        <div className="row">
          <div className="col mb-2">
            <label htmlFor="collectionName" className="form-label fw-bold">
              Collection Name
            </label>
            <select
              id="collectionName"
              name="collectionName"
              required
              className="form-select form-select-md"
              aria-label="Form Select"
              onChange={(e) => handleChange(e)}
              defaultValue={"Select a Collection"}
            >
              <option disabled value="Select a Collection">
                Select a Collection
              </option>
              <option value="database">Database</option>
              <option value="hardware">Hardware</option>
              <option value="software">Software</option>
            </select>
          </div>

          <div className="col mb-2">
            <label htmlFor="context" className="form-label fw-bold">
              Context
            </label>
            <input
              id="context"
              type="text"
              className="form-control"
              placeholder="Context"
              name="context"
              required
              onChange={(e) => handleChange(e)}
              value={state.context}
            />
          </div>
          <div className="col mb-2">
            <label htmlFor="description" className="form-label fw-bold">
              Description
            </label>
            <input
              id="description"
              type="text"
              className="form-control"
              placeholder="Description"
              name="description"
              required
              onChange={(e) => handleChange(e)}
              value={state.description}
            />
          </div>
        </div>
        <div className="d-flex justify-content-center mt-2">
          <button
            type="submit"
            className="btn btn-primary d-flex align-items-center"
          >
            Save <AiOutlineSave style={styles.icon22} />
          </button>
        </div>
      </form>
    </div>
  );
}
