import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import { db } from "../firebase";
import { doc, updateDoc, deleteField } from "firebase/firestore";
import { Button } from "react-bootstrap";
import AdminModal from "../components/AdminModal";
import { useAuthContext } from "../components/AuthContext";
import { GetAllUsersDocs } from "../functions/GetAllUsersDocs";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ErrorNotification from "../components/ErrorNotification";
import logError from "../functions/logError";

const styles = {
  cellWidth: {
    minWidth: "120px",
  },
};

export default function AdminDashboard() {
  const navigate = useNavigate();
  const [appointments, setAppointments] = useState<any[][] | null>(null);
  const [modalObject, setModalObject] = useState({
    modalShow: false,
    appointmentId: "",
    obj: {},
  });
  const [errorMessage, setErrorMessage] = useState<string | null>(null); // This will be used to display error messages to the User
  const { scheduleData, currentUserData, logout } = useAuthContext();

  // this effect will get all user appointments from firestore
  useEffect(() => {
    const docs = GetAllUsersDocs(scheduleData);
    setAppointments(docs);
  }, [scheduleData]);

  if (currentUserData?.isAdmin === false) {
    try {
      async function logoutFunc() {
        await logout();
      }
      logoutFunc();
    } catch (error: any) {
      // This will render an error message to the user from the navbar > passed through context
      setErrorMessage("An error occurred while logging out.");
      logError(
        "Administrator",
        `Error: ${error.message} | ${error.code} | ${error.name} | ${error.stack}`,
        "No appointment data",
        "AdminDashboard.tsx"
      ).then((result) => {
        console.log("Error logging to the database.");
      });
    } finally {
      navigate("/login"); // Redirect to the login page
    }
  }

  const docRef = doc(db, "appointments", "scheduled");

  async function handleDelete(id: string) {
    try {
      if (
        window.confirm(
          "Are you sure you want to delete this Appointment?  Is it completed or paid?  Double Check!"
        ) === true
      ) {
        await updateDoc(docRef, {
          [id]: deleteField(),
        });
        alert("Appointment Deleted");
      } else {
        return;
      }
    } catch (error: any) {
      logError(
        "Administrator",
        `Error: ${error.message} | ${error.code} | ${error.name} | ${error.stack}`,
        "No appointment data",
        "AdminDashboard.tsx"
      ).then((result) => {
        console.log("Error logging to the database.");
      });
      setErrorMessage("An error occurred while deleting the appointment.");
    }
  }

  function openModal(id: string, obj: any) {
    setModalObject({ modalShow: true, appointmentId: id, obj: obj });
  }

  return (
    <div className="container">
      <div className="d-flex justify-content-start mt-2">
        <Link
          to="/completed-appointments"
          className="btn btn-warning ms-2"
          role="button"
        >
          Completed Appointments
        </Link>
        <Link
          to="/paid-appointments"
          className="btn btn-success ms-2"
          role="button"
        >
          Paid Appointments
        </Link>
        <Link to="/errorlog" className="btn btn-primary ms-2" role="button">
          Error Log
        </Link>
        <Link
          to="/config-change-log"
          className="btn btn-secondary ms-2"
          role="button"
        >
          Config Log
        </Link>
        <Link to="/change-log-form" className="btn btn-danger ms-2" role="button">
          Log Form
        </Link>
      </div>
      {errorMessage ? (
        <ErrorNotification
          message={errorMessage}
          timeLength={5000}
          setErrorMessage={setErrorMessage}
        />
      ) : null}
      {!appointments ? (
        <div className="container">
          <h1 className="text-center fst-italic">Admin Dashboard</h1>

          <p className="text-center">No appointments scheduled</p>
        </div>
      ) : (
        <div className="container">
          <h1 className="text-center fst-italic">Admin Dashboard</h1>

          <Table responsive="md" striped bordered hover size="sm">
            <thead>
              <tr>
                <th>#</th>
                <th>Date</th>
                <th>Time</th>
                <th>isRemote</th>
                <th>description</th>
                <th>notes</th>
                <th>email</th>
                <th>isCompleted</th>
                <th>isPaid</th>
                <th>Amount</th>
                <th className="text-nowrap">User Info</th>
                <th className="text-nowrap">Cancel Now</th>
              </tr>
            </thead>
            <tbody>
              {appointments?.map((item: any, index: number) => {
                let formattedDate: string = "";

                if (item[1] && item[1].date) {
                  let text = item[1].date;
                  let year = text.slice(0, 4);
                  let month = text.slice(4, 6);
                  let day = text.slice(6, 8);
                  formattedDate = `${month}/${day}/${year}`;
                } else {
                  return <tr className="h6">Error loading appointment!</tr>;
                }
                return (
                  <tr key={item[0] as string}>
                    <td>{index + 1}</td>
                    <td>{formattedDate}</td>
                    <td>{item[1].time}</td>
                    <td>{item[1].isRemote.toString()}</td>
                    <td>
                      <span
                        className="form-control text-wrap text-break"
                        style={styles.cellWidth}
                      >
                        {item[1].description}
                      </span>
                    </td>
                    <td>
                      <span
                        className="form-control text-wrap text-break"
                        style={styles.cellWidth}
                      >
                        {item[1].notes}
                      </span>
                    </td>
                    <td>{item[1].email}</td>
                    <td>{item[1].isCompleted.toString()}</td>
                    <td>{item[1].isPaid.toString()}</td>

                    <td>{`${item[1].amount}`}</td>
                    <td>
                      <Button
                        variant="warning"
                        size="sm"
                        onClick={() => {
                          openModal(item[0], item[1]);
                        }}
                      >
                        User Info
                      </Button>
                    </td>
                    <td>
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={() => handleDelete(item[0])}
                      >
                        Cancel Appointment
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {modalObject.modalShow ? (
            <AdminModal
              setModalObject={setModalObject}
              modalObject={modalObject}
              //handleDelete={handleDelete}
            />
          ) : null}
        </div>
      )}
    </div>
  );
}
