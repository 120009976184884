import React from "react";

export default function Footer() {
  return (
    <footer className="container-fluid bg-primary mt-auto">
      <div className="row fw-bold py-3">
        <div className="col-md-6 order-1 d-flex justify-content-center fs-6 text-nowrap">
          &#169;{new Date().getFullYear()} Simple Tech Care LLC
        </div>
        <div className="text-warning col-md-6 order-2 d-flex justify-content-center">
          <div className="row">
            <span className="col fs-6 text-nowrap d-flex justify-content-center">
              Contact Support:
            </span>
            <a
              href="mailto:help@simpletechcare.com?subject=Simpletechcare.com Support"
              className="col text-decoration-none text-dark link-danger fs-6 text-nowrap d-flex justify-content-center"
            >
              help@simpletechcare.com
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
