import React, { useEffect, useState } from "react";
import { Link, useRouteError } from "react-router-dom";
import { TbAlertTriangle } from "react-icons/tb";
import { BiLogIn } from "react-icons/bi";
import logError from "../functions/logError";
import ErrorNotification from "./ErrorNotification";

const styles = {
  icon20: { fontSize: 20 },
};

const TIME_LENGTH = 5000;

export default function ErrorPage({
  message = "An unexpected error has occurred.",
}) {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const error: any = useRouteError();

  useEffect(() => {
    if (error) {
      logError(
        "admin",
        `Error: ${error.name}, Message: ${error.message}, Stack: ${error.stack}`,
        "none",
        "ErrorPage.tsx"
      ).catch((loggingError) => {
        console.error("Error occurred while logging the error.", loggingError);
      });
      setErrorMessage(
        "An unexpected error has occurred. Please try again later. If the problem persists, please contact support."
      );
    }
  }, [error]); // Only re-run the effect if the error changes

  return (
    <div className="container text-center">
      <TbAlertTriangle size={100} />
      <h1>Oops!</h1>
      <p>Sorry, {message}</p>
      {errorMessage && (
        <ErrorNotification
          message="You will be redirected to the login page in 5 seconds."
          timeLength={TIME_LENGTH}
          setErrorMessage={setErrorMessage}
        />
      )}
      <Link to={"/login"} className="btn btn-primary">
        Login <BiLogIn style={styles.icon20} className="ms-1" />
      </Link>
    </div>
  );
}
