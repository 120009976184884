import React, { useState, useEffect, useCallback } from "react";
import Table from "react-bootstrap/Table";
import { db } from "../firebase";
import { Button } from "react-bootstrap";
import { GetAllUsersDocs } from "../functions/GetAllUsersDocs";
import { Link } from "react-router-dom";
import { deleteField, doc, getDoc, updateDoc } from "firebase/firestore";
import logError from "../functions/logError";
import ErrorNotification from "../components/ErrorNotification";

//const styles = {
//  cellWidth: {
//   minWidth: "120px",
// },
//};

const TIME_LENGTH = 5000;

export default function ErrorLogTable() {
  const [errorLog, setErrorLog] = useState<any[][] | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null); // This will be used to display error messages to the User
  

  const fetchErrorDocs = useCallback(async()=>{
    const docError = doc(db, "errorTable", "errors");
    await getDoc(docError)
      .then((docSnap) => {
        if (docSnap.exists()) {
          let docData = docSnap.data();
          let data = GetAllUsersDocs(docData);
          setErrorLog(data);
        }
      })
      .catch((error) => {
        logError(
          "Administrator",
          `Error: ${error.name} ${error.message} ${error.stack}`,
          "No appointment data",
          "PaidAppointments.tsx"
        ).then((result) => {
          console.log("Error logging to the database.");
        });

        setErrorMessage("An error occurred while deleting the appointment.");
      });
  },[])

  useEffect(() => {      
    fetchErrorDocs();
  }, [fetchErrorDocs]);

  async function handleDelete(id: string) {
    if (
      window.confirm("Are you sure you want to cancel the Appointment?") ===
      true
    ) {
      try {
        const docRef = doc(db, "errorTable", "errors");
        const docSnap = await getDoc(docRef);

        // / ?.[id] is optional chaining that returns undefined if data() is null or undefined

        if (!docSnap.exists() || !docSnap.data()?.[id]) {
          //[id]

          // The arguments fof logout are (userId: string, errorMessage: string, appointmentIdentifier: string, componentName: string)
          logError(
            "Administrator",
            `Error reason: if (!docSnap.exists() || !docSnap.data()?.[${id}])`,
            id,
            "ErrorLogTable.tsx"
          ).catch((loggingError) => {
            // this is the catch block for the logError function which is a promise
            console.error("Error occurred while logging the error.");
          });

          // Throw an error when the document doesn't exist
          throw new Error(
            "An error occurred while deleting the appointment. An admin has been notified."
          );
        }
        await updateDoc(docRef, {
          [id]: deleteField(),
        });
        // Refresh the error logs after successful deletion
        await fetchErrorDocs();
      } catch (error: any) {
        setErrorMessage(error.message);
      }
    } else {
      return;
    }
  }

  return (
    <div className="container">
      <div className="d-flex justify-content-start mt-2">
        <Link
          to="/completed-appointments"
          className="btn btn-warning ms-2"
          role="button"
        >
          Completed Appointments
        </Link>

        <Link
          to="/paid-appointments"
          className="btn btn-success ms-2"
          role="button"
        >
          Paid Appointments
        </Link>
        <Link
          to="/config-change-log"
          className="btn btn-primary ms-2"
          role="button"
        >
          Config Log
        </Link>
        <Link to="/change-log-form" className="btn btn-secondary ms-2" role="button">
          Log Form
        </Link>
      </div>
      {errorMessage ? (
        <ErrorNotification
          message={errorMessage}
          timeLength={TIME_LENGTH}
          setErrorMessage={setErrorMessage}
        />
      ) : null}
      {!errorLog ? (
        <div className="container">
          <h1 className="text-center fst-italic">Admin Dashboard</h1>
          <p className="text-center">No appointments scheduled</p>
        </div>
      ) : (
        <div className="container">
          <h1 className="text-center fst-italic">Error Log</h1>
          <Table responsive="md" striped bordered hover size="sm">
            <thead>
              <tr>
                <th>#</th>
                <th>errorID</th>
                <th>day</th>
                <th>time</th>
                <th>userId</th>
                <th>errorMessage</th>
                <th>appointmentIdentifier</th>
                <th>componentName</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {errorLog?.map((item: any, index: number) => {
                return (
                  <tr key={item[0] as string}>
                    <td>{index + 1}</td>
                    <td>{item[0]}</td>
                    <td>{item[1].day}</td>
                    <td>{item[1].time}</td>
                    <td>{item[1].userId}</td>
                    <td>{item[1].errorMessage}</td>
                    <td>{item[1].appointmentIdentifier}</td>
                    <td>{item[1].componentName}</td>
                    <td>
                      <Button
                        variant="warning"
                        size="sm"
                        onClick={() => handleDelete(item[0])}
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      )}
    </div>
  );
}
