import React, { useState, useEffect } from "react";
import {
  checkActionCode,
  applyActionCode,
  sendPasswordResetEmail,
} from "firebase/auth";
import { auth } from "../firebase";
import { Link } from "react-router-dom";
import { BiLogIn } from "react-icons/bi";
import ErrorNotification from "../components/ErrorNotification";
import logError from "../functions/logError";

const styles = {
  icon22: { fontSize: 22 },
};

const TIME_LENGTH = 5000;

export default function EmailRecovery({
  actionCode,
}: {
  actionCode: string | null | undefined;
}) {
  const [reverted, setReverted] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null); // This will be used to display error messages to the User

  useEffect(() => {
    if (!actionCode) {
      return;
    }

    async function handleRecoverEmail(auth: any, actionCode: string) {
      let restoredEmail: string | undefined | null = null;
      try {
        const info = await checkActionCode(auth, actionCode);
        restoredEmail = info.data.email;

        if (restoredEmail) {
          await applyActionCode(auth, actionCode);
          setReverted(true);
          await sendPasswordResetEmail(auth, restoredEmail);
          // Display success message to the user
        } else {
          // Handle the case where restoredEmail is undefined
          logError(
            "Email: " + (restoredEmail || "Undefined"),
            "No such document!",
            "Email Recovery",
            "EmailRecovery.tsx"
          ).catch((loggingError) => {
            // this is the catch block for the logError function which is a promise
            console.error("Error occurred while logging the error.");
          });
          setErrorMessage("Email Recovery Failed.");
        }
      } catch (error: any) {
        logError(
          "Email: " + (restoredEmail || "Undefined"),
          `Error: ${error.name}, Message: ${error.message}, Stack: ${error.stack}`,
          "Email Recovery",
          "EmailRecovery.tsx"
        ).catch((loggingError) => {
          // this is the catch block for the logError function which is a promise
          console.error("Error occurred while logging the error.");
        });
        setErrorMessage("Email Recovery Failed.");
      }
    }

    handleRecoverEmail(auth, actionCode);
  }, [actionCode]);

  return (
    <div>
      {errorMessage ? (
        <ErrorNotification
          message="Error recovering email"
          timeLength={TIME_LENGTH}
          setErrorMessage={setErrorMessage}
        />
      ) : null}

      {reverted ? (
        <div>
          <h1 className="text-center">Your Email has been reverted</h1>
        </div>
      ) : (
        <h1 className="text-center">
          Error Reverting your email, please try again.
        </h1>
      )}
      <div>
        <div className="d-flex justify-content-center">
          <Link className="btn btn-danger mb-2" to="/login">
            Login <BiLogIn style={styles.icon22} />
          </Link>
        </div>
      </div>
    </div>
  );
}
