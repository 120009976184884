import logError from "./logError";


export const GetAllUsersDocs = (docSnap) => {
  
  let userAppointments = [];

  if (!docSnap) {
    // doc.data() will be undefined in this case
    logError(
      "No user ID",
      "Error: Document does not exist",
      "No appointment data",
      "GetAllUserDocs.js"
    ).then((result) => {
      console.log("Error logged to database");
    });
    return null;
  } else {
    // Here we filter the data to only show all user's appointments
    Object.keys(docSnap).forEach((key) => {
      if (typeof docSnap[key] === "object") {
        userAppointments.push([key, docSnap[key]]);
      }
    });

    let userAppointmentsSorted = userAppointments.sort((a, b) => {
      if (a[1].dateAndTime && b[1].dateAndTime) {
        return a[1].dateAndTime.seconds - b[1].dateAndTime.seconds;
      } else {
        return 0;
      }
    });
    return userAppointmentsSorted;
  }
};
