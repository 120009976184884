import { updateDoc } from "firebase/firestore";
import logError from "./logError";


export const UpdateDocData = (docRef, valuesObj) => {
  
  async function updateData() {
    try {
      await updateDoc(docRef, valuesObj);
    } catch (error) {
      logError(
        "No user identifier",
        `Error updating document: ${error.name} ${error.message} ${error.stack}`,
        "No appointment data",
        "UpdateDocData.js"
      ).then((result) => {
        console.log("Error logged to database");
      });
      return false;
    }
  }
  updateData();
  return true;
};
