import React, { useEffect, useState } from "react";
import { auth } from "../firebase";
import { applyActionCode } from "firebase/auth";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import { Alert } from "react-bootstrap";
import { BiLogIn } from "react-icons/bi";
import logError from "../functions/logError";
import AutoCloseModal from "../components/AutoCloseModal";

const styles = {
  icon22: { fontSize: 22 },
};

export default function EmailVerify({
  actionCode,
}: {
  actionCode: string | null;
}) {
  const [verified, setVerified] = useState(false);
  const [showModal, setShowModal] = useState({  // This is used for the AutoCloseModal component
    show: false,
    header: "",
    body: "",
  });

  useEffect(() => {
    if (!actionCode) {
      return;
    }
    async function handleVerifyEmail(auth: any, actionCode: string) {
      // Localize the UI to the selected language as determined by the lang
      // parameter.
      // Try to apply the email verification code.
      await applyActionCode(auth, actionCode)
        .then((resp) => {
          // Email address has been verified.

          // You could also provide the user with a link back to the app.
          setVerified(true);
        })
        .catch((error) => {
          // Code is invalid or expired. Ask the user to verify their email address
          // again.
          
          setShowModal({
            show: true,
            header: "Error",
            body: "Please try to verify your email again. If the problem persists, please contact us.",
          });
          logError(
            "No user id",
            `Error: ${error.name}, Message: ${error.message}, Stack: ${error.stack}`,
            "No appointment identifier",
            "EmailVerify.tsx"
          ).catch((loggingError) => {
            // this is the catch block for the logError function which is a promise
            console.error("Error occurred while logging the error.");
          });
        });
    }

    handleVerifyEmail(auth, actionCode);
  }, [actionCode]);

  return (
    <Container>
      <div className="h5 d-flex justify-content-center mt-2">
        {verified ? (
          <Alert variant="info" className="text-center fw-bold mb-2">
            Email has been verified
          </Alert>
        ) : (
          <Alert variant="info" className="text-center fw-bold mb-2">
            "Error Verifying email, please try again."
          </Alert>
        )}
      </div>
      <div className="d-flex justify-content-center mb-4">
        <Link
          className="btn btn-primary d-flex align-items-center justify-content-center"
          to="/login"
        >
          Login <BiLogIn style={styles.icon22} />
        </Link>
      </div>
          <AutoCloseModal showModal={showModal} setShowModal={setShowModal} />
    </Container>
  );
}
