import React from "react";
import Carousel from "react-bootstrap/Carousel";
import ElderTrust from "../images/elder-trust2.png";
import ElderHelp from "../images/elder-help2.png";
import ElderSmile from "../images/elder-smile2.png";

export default function CarouselSlider() {
  return (
    <div className="container">
      <Carousel>
        <Carousel.Item interval={3000}>
          <img
            src={ElderTrust}
            className="d-block w-100 img-fluid"
            alt="elder trust"
          />
        </Carousel.Item>
        <Carousel.Item interval={3000}>
          <img src={ElderHelp} className="d-block w-100 img-fluid" alt="Help" />
        </Carousel.Item>
        <Carousel.Item interval={3000}>
          <img
            src={ElderSmile}
            className="d-block w-100 img-fluid"
            alt="Smiling Elderly"
          />
        </Carousel.Item>
      </Carousel>
    </div>
  );
}
