import React, { useState } from "react";
import { updateEmail, sendEmailVerification } from "firebase/auth";
import { useAuthContext } from "../components/AuthContext";
import { Container } from "react-bootstrap";
//import { UpdateDocData } from "../functions/UpdateDocData";
//import { doc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { AiOutlineSave } from "react-icons/ai";
import ErrorNotification from "../components/ErrorNotification";
import logError from "../functions/logError";
import AutoCloseModal from "../components/AutoCloseModal";

const styles = {
  icon22: {
    fontSize: 22,
  },
};

const TIME_LENGTH = 5000;



export default function EmailChange() {
  const { currentUser, logout } = useAuthContext();
  const [email, setEmail] = useState("");
  const [confirm_email, setConfirmEmail] = useState("");
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState({  // This is used for the AutoCloseModal component
    show: false,
    header: "",
    body: "",
  });

  const [errorMessage, setErrorMessage] = useState<string | null>(null); // This will be used to display error messages to the User

  function checkEmails(email: string, confirm_email: string) {
    if (email.toLocaleLowerCase() !== confirm_email.toLowerCase()) {
      setShowModal({
        show: true,
        header: "Error",
        body: "Emails do not match.",
      });
      return false;
    }
    return true;
  }

  async function onSubmitHandler(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (checkEmails(email, confirm_email)) {
      updateEmail(currentUser, email)
        .then(() => {
          // Profile updated!
          // Here we update user collection with new email
          //UpdateDocData(docRef, { email: email });
          sendEmailVerification(currentUser).then(async () => {
          
            setShowModal({
              show: true,
              header: "Email Updated",
              body: "Please check your email for verification link.",
            });
            try {
              await logout();
            } catch (error: any) {
              // This will render an error message to the user from the navbar > passed through context
              setErrorMessage("An error occurred while logging out.");
              // After 5 seconds, clear the error
              logError(
                currentUser?.uid,
                `Error: ${error.name}, Message: ${error.message}, Stack: ${error.stack}`,
                "No appointment identifier",
                "EmailChange.tsx"
              ).catch((loggingError) => {
                // this is the catch block for the logError function which is a promise
                console.error("Error occurred while logging the error.");
              });
            } finally {
              navigate("/login");
            }
          });
        })
        .catch((error) => {
          // An error occurred
          setErrorMessage("An error occurred while updating email. Please try again.");
        });
    }
  }

  return (
    <Container>
      {errorMessage ? (
        <ErrorNotification
          message={errorMessage}
          timeLength={TIME_LENGTH}
          setErrorMessage={setErrorMessage}
        />
      ) : null}
      <div className="h1 text-center fst-italic">Change Email</div>
      <div className="h6 text-center text-primary fst-italic">
        Please check your email for verification email and click the link.
      </div>

      <Container className="d-flex justify-content-center">
        <form onSubmit={onSubmitHandler}>
          <label htmlFor="email" className="col-form-label-sm fw-bold">
            Email
          </label>
          <input
            id="email"
            type="email"
            name="email"
            pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
            className="form-control"
            required
            placeholder="Email Address"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />

          <label htmlFor="confirm_email" className="col-form-label-sm fw-bold">
            Repeat Email
          </label>
          <input
            id="confirm_email"
            type="email"
            name="confirm_email"
            pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
            className="form-control"
            required
            placeholder="Confirm Email"
            onChange={(e) => setConfirmEmail(e.target.value)}
            value={confirm_email}
          />
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-primary mt-3 d-flex align-items-center"
              type="submit"
            >
              Save <AiOutlineSave style={styles.icon22} />
            </button>
          </div>

          <br />
        </form>
      </Container>
      <AutoCloseModal showModal={showModal} setShowModal={setShowModal} />
    </Container>
  );
}
