import React, { useState, useEffect, useRef } from "react";
import { useAuthContext } from "../components/AuthContext"; // Import the custom hook
import { Link, useNavigate } from "react-router-dom";
import {
  signInWithPopup,
  GoogleAuthProvider,
  browserSessionPersistence,
} from "firebase/auth";
import { auth } from "../firebase";
import { Container, Button } from "react-bootstrap";
import { FcGoogle } from "react-icons/fc";
import { BiLogIn } from "react-icons/bi";
import { IoCreateOutline } from "react-icons/io5";
import { GrSend } from "react-icons/gr";
import Maintenance from "./Maintenance";
import ErrorNotification from "../components/ErrorNotification";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import delayAction from "../functions/delayAction";

const provider = new GoogleAuthProvider();

const TIME_LENGTH = 5000;

const styles = {
  form: { minWidth: "300px", maxWidth: "700px" },
  icon20: { fontSize: 20 },
  icon22: { fontSize: 22 },
};

interface errorMessageObject {
  header: string;
  message: string;
}

const Login = () => {
  // Use the custom hook to get access to the context value
  const { login, logout } = useAuthContext();
  const navigate = useNavigate();
  const isMounted = useRef(true); // Use a ref to track the mounted status
  const [errorMessage, setErrorMessage] = useState<errorMessageObject | null>(
    null
  ); // This will be used to display error messages to the User

  // Create some state variables for the email and password inputs
  const [emailPassword, setEmailPassword] = useState({
    email: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  useEffect(() => {
    return () => {
      // This will be called on component unmount
      isMounted.current = false;
    };
  }, []);

  const resetForm = () => setEmailPassword({ email: "", password: "" });

  // Create a handler function for the login form submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault(); // Prevent default browser behavior
    try {
      const userCred = await login(emailPassword.email, emailPassword.password); // Call the login function from the context
      resetForm();
      // here we get the returned userCredentials from signInWithEmailAndPassword
      if (!userCred?.emailVerified) {
        // if the user is not verified
        if (isMounted.current) {
          // Check if component is still mounted
          setErrorMessage({
            header: "You must verify your email address before login!",
            message:
              "Please check your inbox (or spam) for no-reply@simpletechcare.com verification email.",
          });
        }
        // Delay the logout process by 5 seconds (or any other time you want)
        await delayAction(5000); // Delay for 5 seconds
        if (isMounted.current) {
          // Check again after the delay
          await logout();
        }
      } else {
        await delayAction(200); // Short delay before navigation
        if (isMounted.current) {
          // Ensure the component is still mounted before navigating
          navigate("/home");
        }
      }
    } catch (error: any) {
      if (isMounted.current) {
        // Check if component is still mounted
        setErrorMessage({
          header: "Oh snap! You got a Login error!",
          message:
            "Either you provided the wrong email or password. Please try again.",
        });
      }
    }
  };

  async function googleLogin() {
    try {
      await auth.setPersistence(browserSessionPersistence);
      // Set the headers for same-origin-allow-popups
      // const headers = new Headers();
      // headers.append("Content-Security-Policy", "frame-ancestors 'self'");
      //, {headers}
      const result = await signInWithPopup(auth, provider);
      if (result.user) {
        resetForm();
        // Here I put a small delay to allow the user currentUserData to be set in the context
        await delayAction(200); // Short delay before navigation
        if (isMounted.current) {
          // Check if component is still mounted before navigating
          navigate("/home");
        }
      }
    } catch (error) {
      if (isMounted.current) {
        setErrorMessage({
          header: "Attention!",
          message: "An error occurred while logging in with Google.",
        });
      }
    }
  }

  if (false) return <Maintenance />;
  else {
    return (
      <Container>
        <div className="h1 text-center fst-italic">Login</div>
        {errorMessage ? (
          <>
            <ErrorNotification
              header={errorMessage.header}
              message={errorMessage.message}
              timeLength={TIME_LENGTH}
              setErrorMessage={setErrorMessage}
            />
            <div className="d-flex justify-content-center">
              <Button variant={"secondary"} onClick={() => navigate(0)}>
                Refresh Page
              </Button>
            </div>
          </>
        ) : null}

        <Container className="d-flex justify-content-center">
          <form onSubmit={handleSubmit} style={styles.form}>
            <div>
              <label htmlFor="email" className="col-form-label-sm fw-bold">
                Email address
              </label>
              <input
                id="email"
                name="email"
                type="email"
                value={emailPassword.email}
                required
                autoComplete="username"
                placeholder="Email address"
                pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
                className="form-control"
                onChange={(e) =>
                  setEmailPassword({ ...emailPassword, email: e.target.value })
                }
              />
            </div>

            <div>
              <label htmlFor="password" className="col-form-label-sm fw-bold">
                Password
              </label>
              <div className="position-relative">
                <input
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  value={emailPassword.password}
                  required
                  autoComplete="current-password"
                  placeholder="Password (min 10 characters)"
                  pattern=".{10,}"
                  className="form-control"
                  onChange={(e) =>
                    setEmailPassword({
                      ...emailPassword,
                      password: e.target.value,
                    })
                  }
                />

                <div
                  onClick={() => setShowPassword(!showPassword)}
                  className="position-absolute top-50 end-0 translate-middle-y"
                >
                  {showPassword ? (
                    <FaRegEyeSlash style={styles.icon22} className="me-3" />
                  ) : (
                    <FaRegEye style={styles.icon22} className="me-3" />
                  )}
                </div>
              </div>
            </div>

            <div className="d-flex">
              <button
                type="submit"
                className="btn btn-primary mt-3 d-flex align-items-center"
              >
                Login <BiLogIn style={styles.icon22} />
              </button>
              <button
                onClick={googleLogin}
                className="btn btn-warning mt-3 ms-3 fw-bold d-flex align-items-center text-primary"
              >
                <FcGoogle style={styles.icon20} /> Sign in with Google
              </button>
            </div>

            <div className="h5 fst-italic mt-2">No account yet?</div>

            <Link to="/signup" className="btn btn-success mt-2">
              Sign up <IoCreateOutline style={styles.icon20} />
            </Link>
            <div className="h5 fst-italic mt-2">Forgot your password?</div>

            <Link to="/forgot-password" className="btn btn-danger mt-2 fw-bold">
              Send Password Reset <GrSend style={styles.icon20} />
            </Link>
          </form>
        </Container>
      </Container>
    );
  }
};

export default Login;
