import React from "react";
import { GrHostMaintenance } from "react-icons/gr";

const styles = {
  icon100: { fontSize: 100 },
};

export default function Maintenance() {
  return (
    <>
      <div className="mt-5 d-flex justify-content-center">
        <GrHostMaintenance style={styles.icon100} />
      </div>
      <div className="h1 d-flex justify-content-center mt-5">
        Sorry, we are down for maintenance now. Be back soon!
      </div>
    </>
  );
}
