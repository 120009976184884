import React, { useState, useCallback } from "react";
import { Button, Container, Table } from "react-bootstrap";
import ErrorNotification from "../components/ErrorNotification";
import logError from "../functions/logError";
import { IoCreateOutline } from "react-icons/io5";
import { deleteField, doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import { GetAllUsersDocs } from "../functions/GetAllUsersDocs";
import { AiOutlineEdit } from "react-icons/ai";
import { Link } from "react-router-dom";
import { MdOutlineCancel } from "react-icons/md";

const TIME_LENGTH = 5000;

const styles = {
  form: {
    minWidth: "300px",
    maxWidth: "700px",
  },
  icon20: { fontSize: 20 },
  icon22: { fontSize: 22, marginLeft: 1 },
  descriptionContainer: {
    minWidth: "120px",
  },
};

export default function ConfigChangeLog() {
  const [errorMessage, setErrorMessage] = useState<string | null>(null); // This will be used to display error messages to the User
  const [collectionName, setCollectionName] = useState<string>("");
  const [tableData, setTableData] = useState<any[][] | null>(null);

  const fetchConfigDocs = useCallback(async()=>{
    if(collectionName !== ""){
    const docRef = doc(db, "config-changes", collectionName);
    await getDoc(docRef)
      .then((docSnap) => {
        if (docSnap.exists()) {
          let docData = docSnap.data();
          let data = GetAllUsersDocs(docData);
          setTableData(data);
        }
      })
      .catch((error) => {
        logError(
          "Administrator",
          `Error: ${error.name} ${error.message} ${error.stack}`,
          "No appointment data",
          "PaidAppointments.tsx"
        ).then((result) => {
          console.log("Error logging to the database.");
        });

        setErrorMessage("An error occurred while deleting the appointment.");
      });
  }
},[collectionName])

 

   function onSubmitHandler(event: React.FormEvent<HTMLFormElement> | any) {
    event.preventDefault(); // Prevent default browser behavior
    
    fetchConfigDocs();
  }

  async function handleDelete(id: string) {
    if (
      window.confirm("Are you sure you want to cancel the Appointment? If YES select OK") ===
      true
    ) {
      try {
        if(collectionName !== ""){
          const docRef = doc(db, "config-changes", collectionName);
          const docSnap = await getDoc(docRef);

        // / ?.[id] is optional chaining that returns undefined if data() is null or undefined

        if (!docSnap.exists() || !docSnap.data()?.[id]) {
          //[id]

          // The arguments fof logout are (userId: string, errorMessage: string, appointmentIdentifier: string, componentName: string)
          logError(
            "Administrator",
            `Error reason: if (!docSnap.exists() || !docSnap.data()?.[${id}])`,
            id,
            "AppointmentTable.tsx"
          ).catch((loggingError) => {
            // this is the catch block for the logError function which is a promise
            console.error("Error occurred while logging the error.");
          });

          // Throw an error when the document doesn't exist
          throw new Error(
            "An error occurred while deleting the appointment. An admin has been notified."
          );
        }
        await updateDoc(docRef, {
          [id]: deleteField(),
        });
      }
      } catch (error: any) {
        setErrorMessage(error.message);
      }
    } else {
      return;
    }
  }

  function handleEdit(id: string, description: string) {}

  return (
    <Container>
      {errorMessage ? (
        <ErrorNotification
          message={errorMessage}
          timeLength={TIME_LENGTH}
          setErrorMessage={setErrorMessage}
        />
      ) : null}
      <div className="d-flex justify-content-start mt-2">
        <Link
          to="/completed-appointments"
          className="btn btn-warning ms-2"
          role="button"
        >
          Completed Appointments
        </Link>
        <Link
          to="/paid-appointments"
          className="btn btn-success ms-2"
          role="button"
        >
          Paid Appointments
        </Link>
        <Link to="/errorlog" className="btn btn-primary ms-2" role="button">
          Error Log
        </Link>
        <Link to="/change-log-form" className="btn btn-secondary ms-2" role="button">
          Log Form
        </Link>
      </div>
      <div className="h1 text-center fst-italic">Config Change Log Table</div>
      <div className="h6 text-center text-primary fst-italic">
        Select the category you would like to view.
      </div>
      <Container className="d-flex justify-content-center">
        <form onSubmit={onSubmitHandler} style={styles.form}>
          <label htmlFor="collection" className="form-label fw-bold">
            Collection
          </label>
          <select
            id="collection"
            name="collection"
            required
            className="form-select form-select-md"
            aria-label="Form Select"
            onChange={(e) => setCollectionName(e.target.value)}
            //value={state.city}
            defaultValue={"Select a Collection"}
          >
            <option disabled value="Select a Collection">Select a Collection</option>
            <option value="database">Database</option>
            <option value="hardware">Hardware</option>
            <option value="software">Software</option>
          </select>
          <div className="d-flex justify-content-center">
            <button type="submit" className="btn btn-success mt-2 mb-2">
              <IoCreateOutline style={styles.icon22} />
              View
            </button>
          </div>
        </form>
      </Container>
      <Table responsive="md" striped bordered hover size="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>Date</th>
            <th>Time</th>
            <th>{collectionName}</th>
            <th>Description</th>
            <th>Context</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {!tableData
            ? null
            : tableData.map((item: any, index: number) => {
             
                return (
                  <tr key={item[0] as string}>
                    <td>{index + 1}</td>
                    <td>{item[1].date}</td>

                    <td>{item[1].time}</td>
                    <td>{item[1].collectionName}</td>

                    <td className="text-nowrap">
                      {item[1].context}
                    </td>
                    <td className="d-flex justify-content-between">
                      <span
                        className="form-control text-wrap text-break me-1"
                        style={styles.descriptionContainer}
                      >
                        {item[1].description}
                      </span>
                      <Button
                        variant="warning"
                        size="sm"
                        onClick={() => handleEdit(item[0], item[1].description)}
                        className="d-flex align-items-center justify-content-center text-nowrap"
                      >
                        Edit <AiOutlineEdit style={styles.icon22} />
                      </Button>
                    </td>
                    <td>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={
                          () => handleDelete(item[0])
                        
                        }
                        className="d-flex align-items-center justify-content-center ms-2 text-nowrap"
                      >
                        Cancel
                        <MdOutlineCancel style={styles.icon22} />
                      </Button>
                    </td>
                  </tr>
                );
              })}
        </tbody>
      </Table>
    </Container>
  );
}
