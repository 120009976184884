import React from "react";
import { Container } from "react-bootstrap";
import logo from "./../images/elder-logo.jpg";
import Image from "react-bootstrap/Image";
import Island from "../images/island.png";
import Technician from "../images/Me.png";

const styles = {
  pic: {
    maxWidth: "300px",
  },
};

export default function Services() {
  return (
    <Container>
      <div className="h1 text-center fst-italic">Services</div>
      <div className="d-flex justify-content-center">
        <img
          src={logo}
          className="img-fluid rounded"
          alt="Hands shaking"
          width={200}
        />
      </div>
      <div className="d-flex justify-content-center">
        <p className="text-wrap text-break mt-2 w-75">
          &nbsp;&nbsp;&nbsp;&nbsp;Simple Tech Care offers technology support services to small businesses and home offices in the Towns of Islip and Smithtown, Suffolk County, NY. With Simple Tech Care, you can rest assured that your technology concerns will be managed by experts, allowing you to focus on what you do best. Our commitment is to provide timely, professional support and solutions that are tailored to the unique needs of small businesses and home offices in Suffolk County, NY.
        </p>
      </div>
      <div className="d-flex justify-content-center">
        <p className="text-wrap text-break w-75">
          &nbsp;&nbsp;&nbsp;&nbsp;Our staff has decades of technology support experience in education and retail environments. We are very proficient with Windows Server, Windows Computers and Laptops, MacBooks, Chromebooks, iPads, printers, and more. In addition to our hardware expertise, we are skilled in computer programming and web design, offering a full suite of services to meet all your technology needs. Our proficiency also extends to network security and infrastructure, including firewalls, routers, switches, and network-attached storage (NAS) systems, ensuring your business is equipped with reliable and secure technology solutions.

Please sign up and let us handle the rest. 
        </p>
      </div>
      <div className="h2 text-center fst-italic">Service Area</div>
      <div className="d-flex justify-content-center">
        <Image src={Island} fluid className="w-75" alt="Long Island service area."/>
      </div>
      <section className="container mt-2 d-flex justify-content-center">
    <ul className="h6 text-wrap text-break w-75">
        <li>
            Simple Tech Care specializes in consultation, software repair, technology updates, upgrades, and troubleshooting services to keep your systems running smoothly.
        </li>
        <li>
            While we do not repair physically broken hardware, we offer comprehensive solutions for hardware replacement. This includes sourcing replacement parts, installation, and configuration to ensure your technology operates at its best.
        </li>
        <li>
            Enhance your online presence with our website setup and maintenance services, tailored to your needs.
        </li>
        <li>
            Tailor your support with our customizable Service Contracts, designed to provide ongoing peace of mind.
        </li>
        <li>
            <span className="h5 text-danger">Troubleshooting Fees:</span> Our expert service is billed at $50 per hour, with a minimum one-hour charge. Subsequent time is billed in 30-minute increments at $25. Time exceeding the initial hour or half-hour by more than 5 minutes will incur an additional 30-minute charge.
        </li>
        <li>
            <span className="h5 text-danger">Consulting Fees:</span> Advanced consulting is available at $100 per hour, with the same billing structure for additional time as our troubleshooting services.
        </li>
        <li>
            Payment is conveniently due at the time of service. We accept a variety of payment methods, including Cash, Check, PayPal, and major Debit or Credit Cards, to accommodate your preferences.
        </li>
    </ul>
</section>
<div className="h3 text-center fst-italic">
  Technical Developer - Christian
</div>
<div className="d-flex justify-content-center mb-2">
  <img src={Technician} className="img-fluid" alt="Christian - Service Technician with 15 years of experience" style={styles.pic}/>
</div>
<div className="text-center mb-3">
  <p className="h5 fst-italic">
    Boasting 15 years of experience in Technology Support.
  </p>
  <p className="h6 fst-italic">
    Holds a Bachelor's Degree in Computer Programming and Information Systems.
  </p>
  <p className="h6 fst-italic">
    Certified as a CompTIA A+ Technician, CompTIA Network+ Technician, and CompTIA Security+ Technician.
  </p>
</div>

    </Container>
  );
}
