import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

interface ShowModalObject {
  show: boolean;
  header: string;
  body: string;
  countdownTime?: number; // Optional countdown time in seconds
}

const AutoCloseModal = ({
  showModal,
  setShowModal,
}: {
  showModal: ShowModalObject;
  setShowModal: (modal: ShowModalObject) => void;
}) => {
  const [timeLeft, setTimeLeft] = useState(showModal.countdownTime || 3); // Default to 3 seconds

  useEffect(() => {
    if (showModal.show) {
      setTimeLeft(showModal.countdownTime || 3); // Reset timer on show

      const timer = setTimeout(() => {
        setShowModal({ ...showModal, show: false });
      }, (showModal.countdownTime || 3) * 1000);

      return () => clearTimeout(timer);
    }
  }, [showModal, setShowModal]);

  useEffect(() => {
    let interval: number;

    if (timeLeft > 0) {
      interval = window.setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [timeLeft]);

  return (
    <Modal show={showModal.show} onHide={() => setShowModal({ ...showModal, show: false })} size="lg" backdrop="static" keyboard={false}>
      <Modal.Header className="text-center bg-warning h4">
        <Modal.Title className="w-100">{showModal.header}</Modal.Title>
        <span className="fw-bold badge rounded-pill text-bg-info">{timeLeft}</span>
      </Modal.Header>
      <Modal.Body className="h4 text-center">
        {showModal.body}           
      </Modal.Body>
    </Modal>
  );
};

export default AutoCloseModal;
