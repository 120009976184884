import React from "react";
import { Container } from "react-bootstrap";

export default function Securified() {
  return (
    <Container>
      <div className="h1 text-center fst-italic">Security Simplified</div>
      <div className="row">
        <div className="col-md-6 order-1">
          <div id="strong-password" className="h4 text-center">
            How to make a strong password.
          </div>
          <div className="ratio ratio-16x9">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/q5DYkzOrz_I?si=dx4lHuZUssf51P2O"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; xr-spatial-tracking"
              allowFullScreen
            ></iframe>
          </div>
          <div className="blockquote bg-light p-2 fs-6 text-center">
            <cite>
              ESET @esetglobal (2017, June 2). The How to make a strong password
              [Video]. https://www.youtube.com/watch?v=q5DYkzOrz_I
            </cite>
          </div>
        </div>
        <div className="col-md-6 order-2">
          <div className="h4 text-center">Best Antivirus for 2024.</div>
          <div className="ratio ratio-16x9">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/0Y08Z1Pmhnc?si=gOpY_w7XBs_pLaue"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; xr-spatial-tracking"
              allowFullScreen
            ></iframe>
          </div>
          <div className="blockquote bg-light p-2 fs-6 text-center">
            <cite>
              VPNpro @VPNprochannel (2017, June 2). Best antivirus 2024 | The
              ACTUAL Top 3 Antivirus (TESTED) [Video].
              https://www.youtube.com/watch?v=0Y08Z1Pmhnc
            </cite>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 order-1">
          <div className="h4 text-center">How to avoid phishing scams.</div>
          <div className="ratio ratio-16x9">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/Y7zNlEMDmI4?si=M498F_5SMeErfCfQ"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; xr-spatial-tracking"
              allowFullScreen
            ></iframe>
          </div>
          <div className="blockquote bg-light p-2 fs-6 text-center">
            <cite>
              TECHtalk @IDGTECHtalk (2019, Aug 28). What is phishing? Learn how
              this attack works [Video].
              https://www.youtube.com/watch?v=Y7zNlEMDmI4
            </cite>
          </div>
        </div>
        <div className="col-md-6 order-2">
          <div className="h4 text-center">How to setup Windows Firewall.</div>
          <div className="ratio ratio-16x9">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/b5YODEKsOrA?si=KaHqWRz1lOupCiQU"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; xr-spatial-tracking"
              allowFullScreen
            ></iframe>
          </div>
          <div className="blockquote bg-light p-2 fs-6 text-center">
            <cite>
              Professor Messer @professormesser (2017, June 2). Configuring
              Windows Firewall - CompTIA A+ 220-1102 - 1.6 [Video].
              https://www.youtube.com/watch?v=b5YODEKsOrA
            </cite>
          </div>
        </div>
      </div>
    </Container>
  );
}
