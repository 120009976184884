import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { auth } from "../firebase";
import { Container } from "react-bootstrap";
import { IoCreateOutline } from "react-icons/io5";
import { BiLogIn } from "react-icons/bi";
import ErrorNotification from "../components/ErrorNotification";
import logError from "../functions/logError";
import { HashLink } from "react-router-hash-link";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import AutoCloseModal from "../components/AutoCloseModal";
import delayAction from "../functions/delayAction";

const TIME_LENGTH = 5000;

const styles = {
  form: {
    minWidth: "300px",
    maxWidth: "700px",
  },
  icon20: { fontSize: 20 },
  icon22: { fontSize: 22, marginLeft: 1 },
};

export default function Signup() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [confirm_email, setConfirmEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showModal, setShowModal] = useState({
    show: false,
    header: "",
    body: "",
  });
  const isMounted = useRef(true); // Use a ref to track the mounted status
  useEffect(() => {
    // No need to set anything on mount here

    return () => {
      // This will be called on component unmount
      isMounted.current = false;
    };
  }, []);

  const [errorMessage, setErrorMessage] = useState<string | null>(null); // This will be used to display error messages to the User

  function checkPasswords(password: string, confirm_password: string) {
    if (password.toLocaleLowerCase() !== confirm_password.toLocaleLowerCase()) {
      setShowModal({
        show: true,
        header: "Passwords do not match",
        body: "Please try again.",
      });
      return false;
    }
    return true;
  }

  function checkEmails(email: string, confirm_email: string) {
    if (email.toLocaleLowerCase() !== confirm_email.toLowerCase()) {
      setShowModal({
        show: true,
        header: "Emails do not match",
        body: "Please try again.",
      });
      return false;
    }
    return true;
  }

  async function onSubmitHandler(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (
      checkEmails(email, confirm_email) &&
      checkPasswords(password, confirm_password)
    ) {
      // Show loading modal
      setShowModal({
        show: true,
        header: "Creating Account",
        body: "Please wait...",
      });

      try {
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        const user = userCredential.user;

        await sendEmailVerification(user);

        // Show success message
        setShowModal({
          show: true,
          header: "Success!",
          body: "Please check your email for verification email and click the link!",
        });

        // Use delayAction instead of setTimeout
        await delayAction(3000); // Delay for 3 seconds before navigating

        if (isMounted.current) {
          // Only navigate if the component is still mounted
          navigate("/login");
        }
      } catch (error: any) {
        // Handle errors
        if (isMounted.current) {
          // Only set Error if the component is still mounted
          setErrorMessage("An error occurred while creating your account.");

          // Log error
          logError(
            "No user id",
            `Error: ${error.name}, Message: ${error.message}, Stack: ${error.stack}`,
            "No appointment identifier",
            "Signup.tsx"
          ).catch((loggingError) => {
            console.error("Error occurred while logging the error.");
          });
        }
        // Reset modal state if needed
        setShowModal({ show: false, header: "", body: "" });
      }
    }
  }

  return (
    <Container>
      {errorMessage ? (
        <ErrorNotification
          message={errorMessage}
          timeLength={TIME_LENGTH}
          setErrorMessage={setErrorMessage}
        />
      ) : null}
      <div className="h1 text-center fst-italic">Sign Up</div>
      <div className="h6 text-center text-primary fst-italic">
        Please check your email for verification email and click the link!
      </div>
      <div className="d-flex justify-content-center">
        <Link to="/login" className="btn btn-warning d-flex" role="button">
          Already have an account?
          <span className="text-primary fw-bold d-flex align-items-center ms-2">
            Login Now <BiLogIn style={styles.icon22} />
          </span>
        </Link>
      </div>
      <Container className="d-flex justify-content-center">
        <form onSubmit={onSubmitHandler} style={styles.form}>
          <label htmlFor="email" className="col-form-label-sm fw-bold">
            Email
          </label>
          <input
            type="email"
            name="email"
            id="email"
            className="form-control"
            pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
            required
            placeholder="Email Address"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />

          <label htmlFor="confirm_email" className="col-form-label-sm fw-bold">
            Repeat Email
          </label>
          <input
            type="email"
            name="confirm_email"
            id="confirm_email"
            className="form-control"
            required
            placeholder="Confirm Email"
            pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
            onChange={(e) => setConfirmEmail(e.target.value)}
            value={confirm_email}
          />
          <div className="h6 fst-italic mt-2 text-danger text-center">
            Need password help? Check out:
            <HashLink
              smooth
              to="/securified/#strong-password"
              className="text-decoration-none ms-2"
            >
              "How to make a strong password"
            </HashLink>
          </div>
          <label htmlFor="password" className="col-form-label-sm fw-bold">
            Password (Must be 10 characters or more)
          </label>
          <div className="position-relative">
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              pattern=".{10,}"
              className="form-control"
              required
              placeholder="Password (min 10 characters)"
              minLength={10}
              onChange={(e) => setPassword(e.target.value)}
              value={password}
            />
            <div
              onClick={() => setShowPassword(!showPassword)}
              className="position-absolute top-50 end-0 translate-middle-y"
            >
              {showPassword ? (
                <FaRegEyeSlash style={styles.icon22} className="me-3" />
              ) : (
                <FaRegEye style={styles.icon22} className="me-3" />
              )}
            </div>
          </div>
          <label
            htmlFor="confirm_password"
            className="col-form-label-sm fw-bold"
          >
            Repeat Password
          </label>
          <div className="position-relative">
            <input
              type={showConfirmPassword ? "text" : "password"}
              id="confirm_password"
              name="confirm_password"
              pattern=".{10,}"
              className="form-control"
              required
              placeholder="Confirm Password"
              minLength={10}
              onChange={(e) => setConfirmPassword(e.target.value)}
              value={confirm_password}
            />
            <div
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              className="position-absolute top-50 end-0 translate-middle-y"
            >
              {showConfirmPassword ? (
                <FaRegEyeSlash style={styles.icon22} className="me-3" />
              ) : (
                <FaRegEye style={styles.icon22} className="me-3" />
              )}
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-primary mt-3 d-flex align-items-center"
              type="submit"
            >
              Sign Up <IoCreateOutline style={styles.icon20} />
            </button>
          </div>
        </form>
      </Container>
      <AutoCloseModal showModal={showModal} setShowModal={setShowModal} />
    </Container>
  );
}
