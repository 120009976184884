import { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import logError from "./logError";

export const useGetInfo = (userId: any) => {
  const [info, setInfo] = useState<any | null>(null);

  useEffect(() => {
    async function getInfo() {
      const docRef = doc(db, "users", userId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setInfo(docSnap.data());
      } else {
        // docSnap.data() will be undefined in this case
        logError(
          userId,
          "No document data",
          "No appointment data",
          "useGetInfo.js"
        ).then((result) => {
          console.log("Error logging to the database.");
        });
      }
    }
    getInfo();
  }, [userId]);
  return info;
};
