import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorPage from './ErrorPage';
import logError from '../functions/logError';

function ErrorFallback({ error }: { error: Error }) {
  logError(
    "Adminstrator",
    `Error: ${error.name}, Message: ${error.message}, Stack: ${error.stack}`,
    "No appointment id",
    "ErrorWrapper.tsx",
    ).then((e) => console.log("Error during logging process."));
  return <ErrorPage message={error.message} />;

}
//The ErrorWrapper (or more generally, error boundaries in React) will only catch unhandled errors that occur
//in their child components. If an error is caught and handled at a lower level in the component tree 
//(for example, within a try/catch block), it will not propagate up to the error boundary.
export default function ErrorWrapper({ children }: { children: React.ReactNode }) {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      {children}
    </ErrorBoundary>
  );
}