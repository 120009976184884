import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "bootstrap/dist/css/bootstrap.css";
import "react-calendar/dist/Calendar.css";
// This will scroll the window to the top when the route changes
import { ScrollRestoration } from "react-router-dom";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Services from "./screens/Services";
import ErrorPage from "./components/ErrorPage";
import Signup from "./screens/Signup";
import Login from "./screens/Login";
import Account from "./screens/Account";
import AdminDashboard from "./screens/AdminDashboard";
import CompletedAppointments from "./screens/CompletedAppointments";
import { Outlet } from "react-router-dom";
import NavbarComponent from "./components/NavbarComponent";
import Footer from "./components/Footer";
import Home from "./screens/Home";
import { FirebaseProvider } from "./components/AuthContext";
import EmailChange from "./screens/EmailChange";
import UserManagement from "./screens/UserManagement";
import ServiceForm from "./screens/ServiceForm";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import MakePayment from "./screens/MakePayment";
import AdminPayment from "./screens/AdminPayment";
import PaidAppointments from "./screens/PaidAppointments";
import RemoteSupport from "./screens/RemoteSupport";
import ProtectedRoute from "./components/ProtectedRoute";
import AdminProtectedRoute from "./components/AdminProtectedRoute";
import ForgotPassword from "./screens/ForgotPassword";
import Maintenance from "./screens/Maintenance";
import Securified from "./screens/Securified";
import ErrorLogTable from "./screens/ErrorLogTable";
import ErrorWrapper from "./components/ErrorWrapper";
import ConfigChangeLog from "./screens/ConfigChangeLog";
import ChangeLogForm from "./components/ChangeLogForm";

const initialOptions = {
  clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID!, // You can use this with create react app and ! tells typescript that it is not null
  currency: "USD",
  intent: "capture",
  components: "buttons,marks",
  vault: false,
  commit: true,
  //sdkVersion: "5.0.410",
};

const styles = {
  layout: { minHeight: "100vh" },
};

// This style is used to make the footer stick to the bottom of the page - Footer has mt-auto
function Layout() {
  return (
    <div className="d-flex flex-column" style={styles.layout}>
      <NavbarComponent />
      <div className="flex-grow-1 flex-shrink-1">
        <Outlet />
      </div>
      <ScrollRestoration />
      <Footer />
    </div>
  );
}

//You should only put components that you want to render based on the URL on the createBrowserRouter list, and use other methods to render components that are not related to the URL, such as importing them directly or using React hooks.
const router = createBrowserRouter([
  {
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <App />,
      },
      {
        path: "/services",
        element: <Services />,
      },
      {
        path: "/signup",
        element: <Signup />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/account",
        element: <ProtectedRoute element={<Account />} />,
      },
      {
        path: "/home",
        element: <Home />,
      },
      {
        path: "/securified",
        element: <Securified />,
      },
      {
        // This path has the same element as the previous route
        // By using a ? mark, the parameter is optional
        path: "/admin",
        element: <AdminProtectedRoute element={<AdminDashboard />} />,
      },
      {
        path: "/emailchange",
        element: <ProtectedRoute element={<EmailChange />} />,
      },
      {
        path: "/usermgmt",
        element: <UserManagement />,
      },
      {
        path: "/serviceform",
        element: <ProtectedRoute element={<ServiceForm />} />,
      },
      {
        path: "/makepayment",
        element: <ProtectedRoute element={<MakePayment />} />,
      },
      {
        path: "/adminpayment",
        element: <AdminProtectedRoute element={<AdminPayment />} />,
      },
      {
        path: "/remotesupport",
        element: <RemoteSupport />,
      },
      {
        path: "/completed-appointments",
        element: <AdminProtectedRoute element={<CompletedAppointments />} />,
      },
      {
        path: "/paid-appointments",
        element: <AdminProtectedRoute element={<PaidAppointments />} />,
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "/maintenance",
        element: <Maintenance />,
      },
      {
        path: "/errorlog",
        element: <AdminProtectedRoute element={<ErrorLogTable />} />,
      },
      {
        path: "/config-change-log",
        element: <AdminProtectedRoute element={<ConfigChangeLog />} />,
      },
      {
        path: "/change-log-form",
        element: <AdminProtectedRoute element={<ChangeLogForm />} />,
      },
    ],
  },
]);
//React.StrictMode. It only activates additional checks and warnings during development mode, and these checks are not performed in production mode.
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ErrorWrapper>
      <FirebaseProvider>
        <PayPalScriptProvider options={initialOptions}>
          <RouterProvider router={router} />
        </PayPalScriptProvider>
      </FirebaseProvider>
    </ErrorWrapper>
  </React.StrictMode>
);
