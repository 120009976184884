export function TimeFind(data: any, date: Date) {
  const formattedDate =
    date.getFullYear() * 1e4 +
    (date.getMonth() + 1) * 100 +
    date.getDate() +
    "";
  const timeArray: string[] = [];
  if (data != null) {
    Object.keys(data).forEach((key) => {
      if (key.slice(0, 8) === formattedDate) {
        timeArray.push(data[key].time);
      }
    });
  }

  return timeArray;
}
