import React from "react";
import { useAuthContext } from "./AuthContext";
import { Navigate } from "react-router-dom";

/*type Props = {
  children: React.ReactNode;
};*/

const ProtectedRoute = ({ element }: { element: any }) => {
  const { currentUser } = useAuthContext();
  if (!currentUser?.emailVerified) {
    return (
      <div>
        <div className="h1 text-center">Not Authorized</div>
        <Navigate to="/login" />
      </div>
    );
  } else {
    return <div>{element}</div>;
  }
};

export default ProtectedRoute;
