import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import { db } from "../firebase";
import { Button } from "react-bootstrap";
import { GetAllUsersDocs } from "../functions/GetAllUsersDocs";
import { Link } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import logError from "../functions/logError";
import ErrorNotification from "../components/ErrorNotification";

const styles = {
  cellWidth: {
    minWidth: "120px",
  },
};

const TIME_LENGTH = 5000;

export default function PaidAppointments() {
  const [appointments, setAppointments] = useState<any[][] | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null); // This will be used to display error messages to the User

  useEffect(() => {
    const docPaid = doc(db, "paid", "transactions");
    async function paidDocs() {
      await getDoc(docPaid)
        .then((docSnap) => {
          if (docSnap.exists()) {
            let docData = docSnap.data();
            let data = GetAllUsersDocs(docData);
            setAppointments(data);
          }
        })
        .catch((error) => {
          logError(
            "Administrator",
            `Error: ${error.name} ${error.message} ${error.stack}`,
            "No appointment data",
            "PaidAppointments.tsx"
          ).then((result) => {
            console.log("Error logging to the database.");
          });

          setErrorMessage("An error occurred while deleting the appointment.");
        });
    }
    paidDocs();
  }, []);

  return (
    <div className="container">
      <div className="d-flex justify-content-start mt-2">
        <Link
          to="/completed-appointments"
          className="btn btn-warning ms-2"
          role="button"
        >
          Completed Appointments
        </Link>
        <Link to="/errorlog" className="btn btn-primary ms-2" role="button">
          Error Log
        </Link>
        <Link
          to="/config-change-log"
          className="btn btn-success ms-2"
          role="button"
        >
          Config Log
        </Link>
        <Link to="/change-log-form" className="btn btn-secondary ms-2" role="button">
          Log Form
        </Link>
      </div>
      {errorMessage ? (
        <ErrorNotification
          message={errorMessage}
          timeLength={TIME_LENGTH}
          setErrorMessage={setErrorMessage}
        />
      ) : null}
      {!appointments ? (
        <div className="container">
          <h1 className="text-center fst-italic">Admin Dashboard</h1>
          <p className="text-center">No appointments scheduled</p>
        </div>
      ) : (
        <div className="container">
          <h1 className="text-center fst-italic">Paid Appointments</h1>
          <Table responsive="md" striped bordered hover size="sm">
            <thead>
              <tr>
                <th>#</th>
                <th>AppointmentID</th>
                <th>Date</th>
                <th>Time</th>
                <th>isRemote</th>
                <th>description</th>
                <th>notes</th>
                <th>email</th>

                <th>Amount</th>
                <th className="text-nowrap">User Info</th>
              </tr>
            </thead>
            <tbody>
              {appointments?.map((item: any, index: number) => {
                let formattedDate: string = "";

                if (item[1] && item[1].date) {
                  let text = item[1].date;
                  let year = text.slice(0, 4);
                  let month = text.slice(4, 6);
                  let day = text.slice(6, 8);
                  formattedDate = `${month}/${day}/${year}`;
                } else {
                  return <tr className="h6">Error loading appointment!</tr>;
                }
                return (
                  <tr key={item[0] as string}>
                    <td>{index + 1}</td>
                    <td>{item[0]}</td>
                    <td>{formattedDate}</td>
                    <td>{item[1].time}</td>
                    <td>{item[1].isRemote}</td>
                    <td>
                      <span
                        className="form-control text-wrap text-break"
                        style={styles.cellWidth}
                      >
                        {item[1].description}
                      </span>
                    </td>
                    <td>
                      <span
                        className="form-control text-wrap text-break"
                        style={styles.cellWidth}
                      >
                        {item[1].notes}
                      </span>
                    </td>
                    <td>{item[1].email}</td>

                    <td>{`${item[1].amount}`}</td>
                    <td>
                      <Button variant="warning" size="sm">
                        User Info
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      )}
    </div>
  );
}
