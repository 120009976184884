import React, { useState } from "react";
import { updateDoc, deleteField, doc, getDoc } from "firebase/firestore";
import { useAuthContext } from "./AuthContext";
import { db } from "../firebase";
import { Table, Button, Container } from "react-bootstrap";
import DescriptionModal from "./DescriptionModal";
import { AiOutlineEdit } from "react-icons/ai";
import { MdOutlineCancel, MdPayments } from "react-icons/md";
import { useNavigate } from "react-router";
import ErrorNotification from "./ErrorNotification";
import logError from "../functions/logError";
import AutoCloseModal from "./AutoCloseModal";

const styles = {
  icon22: {
    fontSize: 22,
    marginLeft: 1,
    zIndex: 0,
    minWidth: "30px",
  },
  descriptionContainer: {
    minWidth: "120px",
  },
};

export default function AppointmentTable() {
  const [modalShow, setModalShow] = useState({
    // This is used for the DescriptionModal component
    modalShow: false,
    appointmentId: "",
    description: "",
  });
  const [errorMessage, setErrorMessage] = useState<string | null>(null); // This will be used to display error messages to the User
  const [showModal, setShowModal] = useState({
    // This is used for the AutoCloseModal component
    show: false,
    header: "",
    body: "",
  });

  const navigate = useNavigate();

  const { userObjs, currentUserData, currentUser } = useAuthContext();

  //const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  // setIsRemote(event.target.checked);
  //};

  let serviceLocation = currentUserData
    ? `${currentUserData.streetAddress}, ${currentUserData.city}, ${currentUserData.state} ${currentUserData.zipCode}`
    : "Loading... Location not available yet.";

  const docRef = doc(db, "appointments", "scheduled");

  async function handleDelete(id: string) {
    if (
      window.confirm(
        "Are you sure you want to cancel the Appointment? If YES select OK"
      ) === true
    ) {
      try {
        const docSnap = await getDoc(docRef);

        // / ?.[id] is optional chaining that returns undefined if data() is null or undefined

        if (!docSnap.exists() || !docSnap.data()?.[id]) {
          //[id]

          // The arguments fof logout are (userId: string, errorMessage: string, appointmentIdentifier: string, componentName: string)
          logError(
            currentUser.uid,
            `Error reason: if (!docSnap.exists() || !docSnap.data()?.[${id}])`,
            id,
            "AppointmentTable.tsx"
          ).catch((loggingError) => {
            // this is the catch block for the logError function which is a promise
            console.error("Error occurred while logging the error.");
          });

          // Throw an error when the document doesn't exist
          throw new Error(
            "An error occurred while deleting the appointment. An admin has been notified."
          );
        }
        await updateDoc(docRef, {
          [id]: deleteField(),
        });
      } catch (error: unknown) {
        if (error instanceof Error) {
          setErrorMessage(error.message);
        }
      }
    } else {
      return;
    }
  }

  function handleEdit(id: string, description: string) {
    setModalShow({
      modalShow: true,
      appointmentId: id,
      description: description,
    });
  }

  function payHandler(amount: string, appointment_Id: string) {
    if (window.confirm(`Go to checkout to pay $${amount}? If YES select OK`)) {
      navigate("/makepayment", {
        state: { amount: amount, appointment_Id: appointment_Id },
      });

      //navigate("/new-route", { state: { key: "value"} });
    } else {
      return;
    }
  }

  return (
    <Container>
      {errorMessage ? (
        <ErrorNotification
          message={errorMessage}
          timeLength={5000}
          setErrorMessage={setErrorMessage}
        />
      ) : null}
      <Table responsive="md" striped bordered hover size="sm">
        <thead>
          <tr>
            <th>#</th>
            <th>Date</th>
            <th>Time</th>
            <th>Address</th>
            <th>Visit</th>
            <th>Description</th>
            <th>Done</th>
            <th>Paid</th>
            <th>Cost</th>
            <th>Pay Now</th>
            <th>Cancel Now</th>
          </tr>
        </thead>
        <tbody>
          {!userObjs
            ? null
            : userObjs.map((item: any, index: number) => {
                let text = item[1].date;
                let year = text.slice(0, 4);
                let month = text.slice(4, 6);
                let day = text.slice(6, 8);
                let formattedDate = `${month}/${day}/${year}`;

                return (
                  <tr key={item[0] as string}>
                    <td>{index + 1}</td>
                    <td>{formattedDate}</td>

                    <td>{item[1].time}</td>
                    <td>{serviceLocation}</td>
                    <td className="text-nowrap">
                      {item[1].isRemote === true ? "Remote" : "In-Person"}
                    </td>
                    <td className="d-flex justify-content-between">
                      <span
                        className="form-control text-wrap text-break me-1"
                        style={styles.descriptionContainer}
                      >
                        {item[1].description}
                      </span>
                      <Button
                        variant="warning"
                        size="sm"
                        onClick={() => handleEdit(item[0], item[1].description)}
                        className="d-flex align-items-center justify-content-center text-nowrap"
                      >
                        Edit <AiOutlineEdit style={styles.icon22} />
                      </Button>
                    </td>
                    <td>{item[1].isCompleted ? "Yes" : "No"}</td>
                    <td>{item[1].isPaid.toString()}</td>
                    <td>${item[1].amount}</td>
                    <td>
                      <Button
                        variant="success"
                        onClick={
                          item[1].isCompleted === false
                            ? () => {
                                setShowModal({
                                  show: true,
                                  header: "No payment allowed.",
                                  body: "The job is not completed yet!",
                                });
                              }
                            : () => payHandler(item[1].amount, item[0])
                        }
                        className="text-nowrap"
                      >
                        Pay <MdPayments style={styles.icon22} />
                      </Button>
                    </td>
                    <td>
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={
                          item[1].isCompleted === false
                            ? () => handleDelete(item[0])
                            : () =>
                                // Show success message
                                setShowModal({
                                  show: true,
                                  header: "No action allowed!",
                                  body: "You can not cancel a completed job!",
                                })
                        }
                        className="d-flex align-items-center justify-content-center ms-2 text-nowrap"
                      >
                        Cancel
                        <MdOutlineCancel style={styles.icon22} />
                      </Button>
                    </td>
                  </tr>
                );
              })}
        </tbody>
      </Table>
      <DescriptionModal
        modalShow={modalShow.modalShow}
        appointmentId={modalShow.appointmentId}
        description={modalShow.description}
        setModalShow={setModalShow}
      />
      <AutoCloseModal showModal={showModal} setShowModal={setShowModal} />
    </Container>
  );
}
