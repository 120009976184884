import PasswordReset from "./PasswordReset";
import EmailRecovery from "./EmailRecovery";
import EmailVerify from "./EmailVerify";
import ErrorPage from "../components/ErrorPage";
import { Container } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";

export default function UserManagement() {
  const [searchParams] = useSearchParams();

  const mode: string | null = searchParams.get("mode"); // 'resetPassword'
  const actionCode: string | null = searchParams.get("oobCode"); // 'wXLLClSrLVYhrS2KZArlfXl-MXydkmCfrsmUOMsDsacAAAGK1-nFuw'
  //const apiKey = searchParams.get('apiKey'); // 'AIzaSyCaL8SpobqJB0jJfTXu4fKo6nwdrGrDp0A'
  //const lang = searchParams.get('lang'); // 'en'

  function renderComponent(
    mode: string | null = null,
    actionCode: string | null = null
  ) {
    switch (mode) {
      case "resetPassword":
        // Display reset password handler and UI.
        //handleResetPassword(auth, actionCode, continueUrl, lang);
        return <PasswordReset actionCode={actionCode} />;

      case "recoverEmail":
        // Display email recovery handler and UI.
        // handleRecoverEmail(auth, actionCode, lang);
        return <EmailRecovery actionCode={actionCode} />;

      case "verifyEmail":
        // Display email verification handler and UI.
        // handleVerifyEmail(auth, actionCode, continueUrl, lang);
        return <EmailVerify actionCode={actionCode} />;

      default:
        // Error: invalid mode.
        return <ErrorPage message="Invalid mode!" />;
    }
  }

  return <Container>{renderComponent(mode, actionCode)}</Container>;
}
