import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import { doc, getDoc, updateDoc, deleteField } from "firebase/firestore";
import { GetAllUsersDocs } from "../functions/GetAllUsersDocs";
import { Button, Table } from "react-bootstrap";
import CompletedModal from "../components/CompletedModal";
import { Link } from "react-router-dom";
import logError from "../functions/logError";
import ErrorNotification from "../components/ErrorNotification";

const TIME_LENGTH = 5000;

export default function CompletedAppointments() {
  const [completedAppointments, setCompletedAppointments] = useState<any>(null);
  const [modalObject, setModalObject] = useState({
    modalShow: false,
    appointmentId: "",
    obj: {},
  });
  const [updatedData, setUpdatedData] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null); // This will be used to display error messages to the User

  useEffect(() => {
    setUpdatedData(false);
    const docRef = doc(db, "completed", "past-appointments");
    async function getData() {
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = GetAllUsersDocs(docSnap.data());
        if (data !== null) {
          setCompletedAppointments(data);
        } else {
          return;
        }
      }
    }
    getData();
  }, [updatedData]);

  async function handleDelete(id: string) {
    const docRefCompleted = doc(db, "completed", "past-appointments");
    const docRefAppointments = doc(db, "appointments", "scheduled");
    try {
      if (
        window.confirm(
          "Are you sure you want to cancel the Appointment? Is it completed or paid?  Double Check!"
        ) === true
      ) {
        await updateDoc(docRefCompleted, {
          [id]: deleteField(),
        });
        await updateDoc(docRefAppointments, {
          [id]: deleteField(),
        });
        alert("Appointment Deleted from tables: Appointments and Completed");
      } else {
        return;
      }
    } catch (error: any) {
      logError(
        "Administrator",
        `Error: ${error.name} ${error.message} ${error.stack}`,
        id,
        "CompletedAppointments.tsx"
      ).then((result) => {
        console.log("Error logging to the database.");
      });

      setErrorMessage("An error occurred while deleting the appointment.");
    }
  }

  function openModal(id: string, obj: any) {
    setModalObject({ modalShow: true, appointmentId: id, obj: obj });
  }

  return (
    <div className="container">
      <div className="d-flex justify-content-start mt-2">
        <Link
          to="/paid-appointments"
          className="btn btn-warning ms-2"
          role="button"
        >
          Paid Appointments
        </Link>
        <Link to="/errorlog" className="btn btn-primary ms-2" role="button">
          Error Log
        </Link>
        <Link
          to="/config-change-log"
          className="btn btn-success ms-2"
          role="button"
        >
          Config Log
        </Link>
        <Link to="/change-log-form" className="btn btn-secondary ms-2" role="button">
          Log Form
        </Link>
      </div>
      {errorMessage ? (
        <ErrorNotification
          message={errorMessage}
          timeLength={TIME_LENGTH}
          setErrorMessage={setErrorMessage}
        />
      ) : null}
      {!completedAppointments ? (
        <div className="container">
          <h1 className="text-center fst-italic">Completed Appointments</h1>
          <p>No appointments completed</p>
        </div>
      ) : (
        <div className="container">
          <h1 className="text-center fst-italic">Completed Appointments</h1>
          <Table responsive="md" striped bordered hover size="sm">
            <thead>
              <tr>
                <th>#</th>
                <th>Appointment Date</th>
                <th>Appointment Time</th>
                <th>description</th>
                <th>notes</th>
                <th>isCompleted</th>
                <th>isPaid</th>
                <th>Visit Type</th>
                <th>Amount</th>
                <th>User Info</th>
                <th>Cancel Appointment</th>
              </tr>
            </thead>
            <tbody>
              {completedAppointments.map((item: any, index: number) => {
                let text = item[1].date;
                let year = text.slice(0, 4);
                let month = text.slice(4, 6);
                let day = text.slice(6, 8);
                let formattedDate = `${month}/${day}/${year}`;

                return (
                  <tr key={item[0] as string}>
                    <td>{index + 1}</td>
                    <td>{formattedDate}</td>
                    <td>{item[1].time}</td>
                    <td>
                      <span className="form-control text-wrap text-break">
                        {item[1].description}
                      </span>
                    </td>
                    <td>
                      <span className="form-control text-wrap text-break">
                        {item[1].notes}
                      </span>
                    </td>
                    <td>{item[1].isCompleted.toString()}</td>
                    <td>{item[1].isPaid.toString()}</td>
                    <td>{item[1].isRemote.toString()}</td>
                    <td>{`${item[1].amount}`}</td>
                    <td>
                      <Button
                        variant="warning"
                        size="sm"
                        onClick={() => {
                          openModal(item[0], item[1]);
                        }}
                      >
                        User Info
                      </Button>
                    </td>
                    <td>
                      <Button
                        variant="primary"
                        size="sm"
                        onClick={() => handleDelete(item[0])}
                      >
                        Delete Appointment
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {modalObject.modalShow ? (
            <CompletedModal
              setModalObject={setModalObject}
              modalObject={modalObject}
              setUpdatedData={setUpdatedData}
              handleDelete={handleDelete}
            />
          ) : null}
        </div>
      )}
    </div>
  );
}
