import React, { useState } from "react"; // was using useRef
import { Container } from "react-bootstrap";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { useLocation } from "react-router";
import { doc, updateDoc, deleteField, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import LoadingIcon from "../components/LoadingIcon";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../components/AuthContext";
import logError from "../functions/logError";
import ErrorNotification from "../components/ErrorNotification";
import AutoCloseModal from "../components/AutoCloseModal";

export default function MakePayment() {
  const [{ isPending, isRejected }] = usePayPalScriptReducer();
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser } = useAuthContext();
  const [errorMessage, setErrorMessage] = useState<string | null>(null); // This will be used to display error messages to the User
  const [showModal, setShowModal] = useState({
    // This is used for the AutoCloseModal component
    show: false,
    header: "",
    body: "",
  });

  const TIME_LENGTH = 5000;

  if (isRejected) {
    return <h1 className="text-center">Error loading PayPal scripts!</h1>;
  }
  //const { currentUser } = useAuthContext();
  //const amountRef = useRef<string>(""); // this is an object with one property called current

  async function handleDelete(id: string) {
    const docRefCompleted = doc(db, "completed", "past-appointments");
    const docRefAppointments = doc(db, "appointments", "scheduled");
    const docRefPaid = doc(db, "paid", "transactions");
    let data: any = null;
    let paidRecord: any = null;

    let identifier: any = location?.state.appointment_Id;
    try {
      const docSnap = await getDoc(docRefAppointments);
      if (docSnap.exists()) {
        data = docSnap.get(identifier).isPaid;
        // This is the map value for appointment id in the docRefAppointments
        paidRecord = docSnap.data()[identifier];
      } else {
        // doc.data() will be undefined in this case
        logError(
          currentUser?.uid,
          "No such document!",
          id,
          "MakePayment.tsx"
        ).catch((loggingError) => {
          // this is the catch block for the logError function which is a promise
          console.error("Error occurred while logging the error.");
          setErrorMessage("Error during Payment Process.");
        });
      }
    } catch (error: any) {
      logError(
        currentUser?.uid,
        `Error: ${error.name}, Message: ${error.message}, Stack: ${error.stack}`,
        id,
        "MakePayment.tsx"
      ).catch((loggingError) => {
        // this is the catch block for the logError function which is a promise
        console.error("Error occurred while logging the error.");
      });
      setErrorMessage("Error during Payment Process.");
    }

    if (data !== null && data === true) {
      try {
        async function dataUpdate() {
          await updateDoc(docRefPaid, {
            [identifier]: paidRecord,
          });
        }
        dataUpdate();

        await updateDoc(docRefCompleted, {
          [id]: deleteField(),
        });
        await updateDoc(docRefAppointments, {
          [id]: deleteField(),
        });
      } catch (error: any) {
        // The arguments fof logout are (userId: string, errorMessage: string, appointmentIdentifier: string, componentName: string)
        logError(
          currentUser?.uid,
          `Error: ${error.name}, Message: ${error.message}, Stack: ${error.stack}`,
          id,
          "MakePayment.tsx"
        ).catch((loggingError) => {
          // this is the catch block for the logError function which is a promise
          console.error("Error occurred while logging the error.");
        });
        setErrorMessage("Error during Payment Process.");
      }
    }
  }

  const createOrder = async (data: any, actions: any) => {
    const orderAmount = parseFloat(location?.state.amount).toFixed(2) || "1.00";

    return await actions.order.create({
      purchase_units: [
        {
          description: `${currentUser?.email} # ${location?.state.appointment_Id}`,

          amount: {
            value: orderAmount,
          },
        },
      ],
    });
  };

  const onApprove = async (data: any, actions: any) => {
    try {
      const details = await actions.order.capture();
      // here we need to update the appointment to paid and add to paid collection in firebase

      const docRef = doc(db, "appointments", "scheduled");
      const data = { [`${location.state.appointment_Id}.isPaid`]: true }; // the property isPaid is set to true

      await updateDoc(docRef, data); //data is an object
      await handleDelete(location.state.appointment_Id);

      setShowModal({
        show: true,
        header: "Success!",
        body:
          "Thank you for your payment " + details.payer.name.given_name + "!",
      });
      // cleanup is not necessary because the compenent will unmount after the redirect
      setTimeout(() => navigate("/home"), 3000); // 3000 ms delay before navigating
    } catch (err: any) {
      // Log the error for debugging
      logError(
        currentUser?.uid,
        `Error: ${err.name}, Message: ${err.message}, Stack: ${err.stack}`,
        location.state.appointment_Id,
        "MakePayment.tsx"
      ).catch((loggingError) => {
        // this is the catch block for the logError function which is a promise
        console.error("Error occurred while logging the error.");
      });
      setErrorMessage("Error during Approval Process.");
    }
  };

  const onError = (err: any) => {
    // Log the error for debugging
    logError(
      currentUser.uid,
      `Error: ${err.name}, Message: ${err.message}, Stack: ${err.stack}`,
      location.state.appointment_Id,
      "MakePayment.tsx"
    ).catch((loggingError) => {
      // this is the catch block for the logError function which is a promise
      console.error("Error occurred while logging the error.");
    });

    // Set the error information and show the error notification
    setErrorMessage("Error during Payment Process.");
  };

  return (
    <Container>
      <div className="h1 text-center text-primary mb-2 fst-italic">
        Checkout
      </div>
      <div className="h2 fst-italic text-center mb-3">
        Make a Payment of ${location?.state.amount}
      </div>
      {errorMessage ? (
        <ErrorNotification
          message={errorMessage}
          timeLength={TIME_LENGTH}
          setErrorMessage={setErrorMessage}
        />
      ) : null}
      <div className="container d-flex justify-content-center">
        {isPending ? (
          <LoadingIcon />
        ) : (
          <PayPalButtons
            createOrder={createOrder}
            onApprove={onApprove}
            onError={onError}
          />
        )}
      </div>
      <AutoCloseModal showModal={showModal} setShowModal={setShowModal} />
    </Container>
  );
}
