import { useCallback, useReducer, useEffect } from "react";
import {
  browserSessionPersistence,
  onAuthStateChanged,
  setPersistence,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { doc, onSnapshot } from "firebase/firestore";
import { GetUserObjs } from "../functions/GetUserObjs";
import { auth, db } from "../firebase";


//By default local storage is used for persistence.

function reducer(state: any, action: any) {
  switch (action.type) {
    case "currentUser":
      return { ...state, currentUser: action.payload };
    case "currentUserData":
      return { ...state, currentUserData: action.payload };
    case "scheduleData":
      return { ...state, scheduleData: action.payload };
    case "userObjs":
      return { ...state, userObjs: action.payload };
    case "LOADING":
      return { ...state, loading: action.payload };
    default:
      return state;
  }
}

const initialState = {
  currentUser: null,
  currentUserData: null,
  scheduleData: null,
  userObjs: null,
  loading: false,
};

export function useAuth() {
  const [state, dispatch] = useReducer(reducer, initialState);

    // useCallback caches the function itself. It only recomputes the memoized value when one of the dependencies has changed.  It does not need a return value, just use for side effects.
  const login = useCallback(async (email: string, password: string) => {
    await setPersistence(auth, browserSessionPersistence);
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    return userCredential.user;
  }, []);

  const logout = useCallback(async () => {
    await signOut(auth);
    dispatch({
      type: "currentUser",
      payload: null,
    });
  }, []);

  useEffect(() => {
    dispatch({ type: "LOADING", payload: true });

    const unsubscribe = onAuthStateChanged(auth, (user: any) => {
      if (user) {
        dispatch({
          type: "currentUser",
          payload: user,
        });
      }

      dispatch({ type: "LOADING", payload: false });
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    if (state.currentUser) {
      dispatch({ type: "LOADING", payload: true });

      const unsubscribeSnapshot = onSnapshot(
        doc(db, "appointments", "scheduled"),
        (doc) => {
          let dataSnap = doc.data();
          let userObjs = GetUserObjs(state.currentUser, dataSnap);

          dispatch({
            type: "scheduleData",
            payload: dataSnap,
          });
          dispatch({
            type: "userObjs",
            payload: userObjs,
          });

          dispatch({ type: "LOADING", payload: false });
        },
        (err) => {
          console.error("An error occurred while retrieving data.");
          dispatch({ type: "LOADING", payload: false });
        }
      );

      return () => {
        unsubscribeSnapshot();
      };
    }
  }, [state.currentUser]);

  useEffect(() => {
    dispatch({ type: "LOADING", payload: true });

    if (state.currentUser) {
      const unsubscribeSnapshot = onSnapshot(
        doc(db, "users", state.currentUser.uid),
        (doc) => {
          let dataSnap = doc.data();
          dispatch({
            type: "currentUserData",
            payload: dataSnap,
          });

          dispatch({ type: "LOADING", payload: false });
        },
        (err) => {
          console.error("An error occurred while retrieving data.");
          dispatch({ type: "LOADING", payload: false });
        }
      );

      return () => {
        unsubscribeSnapshot();
      };
    }
  }, [state.currentUser]);

  return {
    currentUser: state.currentUser,
    currentUserData: state.currentUserData,
    scheduleData: state.scheduleData,
    userObjs: state.userObjs,
    loading: state.loading,
    login,
    logout,
  };
}