import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { AiOutlineSave, AiOutlineCloseCircle } from 'react-icons/ai';
import logError from '../functions/logError';
import { useAuthContext } from './AuthContext';
import ErrorNotification from './ErrorNotification';
import DOMPurify from 'dompurify';

const styles = {
  icon22: {
    fontSize: 22,
    marginLeft: 2,
  },
};

const TIME_LENGTH = 5000;

interface DescriptionModalProps {
  modalShow: boolean;
  appointmentId: string;
  description: string;
  setModalShow: (modalState: { modalShow: boolean; appointmentId: string; description: string }) => void;
}

export default function DescriptionModal({ modalShow, appointmentId, description, setModalShow }: DescriptionModalProps) {
  const [text, setText] = React.useState(description);
  const { currentUser } = useAuthContext();
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  const handleClose = () => setModalShow({ modalShow: false, appointmentId: '', description: '' });
  

  const onSubmitHandler = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const sanitizedText = DOMPurify.sanitize(text); // Sanitize the input
    const fieldPath = `${appointmentId}.description`; // Construct the field path
    
    try {
      const docRef = doc(db, 'appointments', 'scheduled');
      await updateDoc(docRef, { [fieldPath]: sanitizedText }); // Update the document
      handleClose(); // Close the modal on successful update
      // Optionally, provide feedback to the user about the successful update
    } catch (error: any) {
      logError(currentUser.uid, `Error: ${error.name}, Message: ${error.message}, Stack: ${error.stack}`, appointmentId, 'DescriptionModal.tsx')
        .catch(loggingError => console.error('Error occurred while logging the error.'));
      setErrorMessage('Error updating description. Please try again.');
    }
  };
  

  React.useEffect(() => {
    setText(description);
  }, [description]);

  return (
    <Modal show={modalShow} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Add Your Problem Description</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {errorMessage && (
          <ErrorNotification
            message={errorMessage}
            timeLength={TIME_LENGTH}
            setErrorMessage={setErrorMessage}
          />
        )}
        <form onSubmit={onSubmitHandler}>
          <label htmlFor="description" className="col-form-label-sm fw-bold">
            Please add a brief summary of your issue here.
          </label>
          <textarea
            id="description"
            name="description"
            rows={4}
            className="form-control text-wrap text-break"
            required
            onChange={(e) => setText(e.target.value)}
            value={text}
            aria-label="Problem Description"
          ></textarea>
          <div className="d-flex justify-content-center">
            <button className="btn btn-primary mt-3 d-flex align-items-center" type="submit">
              Save <AiOutlineSave style={styles.icon22} />
            </button>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose} className="d-flex align-items-center">
          Close <AiOutlineCloseCircle style={styles.icon22} />
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
