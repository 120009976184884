import { doc, setDoc, Timestamp } from "firebase/firestore";
import { db } from "../firebase";
import { v4 as uuidv4 } from "uuid";
import logError from "./logError";

export const SetAppointment = (
  appointment,
  userId,
  formattedDate,
  event,
  email,
  isRemote
) => {
  const DocRef = doc(db, "appointments", "scheduled");

  let randomId = uuidv4();

  let dateRandomId = `${formattedDate}-${randomId}`;
  let timeStampString = new Date(appointment);
  let timeStampObj = Timestamp.fromDate(timeStampString);

  let Data = {
    "dateAndTime": timeStampObj,
    "description": "",
    "isCompleted": false,
    "isPaid": false,
    "isRemote": isRemote,
    "notes": "Enter notes here",
    "reference": doc(db, "users", userId),
    "date": formattedDate,
    "time": event,
    "recordCreated": Timestamp.now(),
    "userId": userId,
    "amount": 0,
    "email": email,
  };
  try {
    async function SetData() {
      await setDoc(
        DocRef,
        {
          // This computed property name syntax is userID string
          [dateRandomId]: Data,
        },
        { merge: true }
      );
    }
    SetData();
  } catch (error) {
    logError(
      userId,
      `Error updating document: ${error.name} ${error.message} ${error.stack}`,
      appointment,
      "SetAppointment.js"
    ).then((result) => {
      console.log("Error logged to database");
    });
    return { success: false, appointId: dateRandomId };
  }

  return { success: true, appointId: dateRandomId };
};
