import React, { useReducer, useState } from "react";
import { CityArray } from "./SuffolkHamlets";
import { setDoc, doc } from "firebase/firestore";
import { useAuthContext } from "./AuthContext";
import { db } from "../firebase";
import { AiOutlineSave } from "react-icons/ai";
import logError from "../functions/logError";
import ErrorNotification from "./ErrorNotification";
import AutoCloseModal from "./AutoCloseModal";

//dispatch function is the action argument passed to reducer function
function reducer(state: any, action: any) {
  switch (action.type) {
    case "firstName":
      return { ...state, firstName: action.payload };
    case "lastName":
      return { ...state, lastName: action.payload };
    case "phoneNumber":
      return { ...state, phoneNumber: action.payload };
    case "streetAddress":
      return { ...state, streetAddress: action.payload };
    case "city":
      return { ...state, city: action.payload };

    default:
      return state;
  }
}

const styles = {
  icon22: {
    fontSize: 22,
    marginLeft: 2,
  },
};

const TIME_LENGTH = 5000;

export default function UserForm({ navigate }: { navigate: any }) {
  const [state, dispatch] = useReducer(reducer, {
    firstName: "",
    lastName: "",
    phoneNumber: "",
    streetAddress: "",
    city: "",
  });

  const [showModal, setShowModal] = useState({  // This is used for the AutoCloseModal component
    show: false,
    header: "",
    body: "",
  });

  const [errorMessage, setErrorMessage] = useState<string | null>(null); // This will be used to display error messages to the User

  const { currentUser } = useAuthContext();

  async function submitHandler(e: any) {
    e.preventDefault();
    const docData = {
      "firstName": state.firstName,
      "lastName": state.lastName,
      "phoneNumber": state.phoneNumber,
      "streetAddress": state.streetAddress,
      "city": state.city,
      "state": "NY",
      "zipCode": e.target.zipCode.value,
      "isCompleted": true,
      "isAdmin": false,
      // "email": currentUser.email,
    };
    if (!currentUser) {
      return;
    } else {
      try {
        const docRef = doc(db, "users", currentUser?.uid);
        await setDoc(docRef, docData);
        
        setShowModal({
          show: true,
          header: "Success!",
          body: "Service Form is completed!",
        });
        navigate("/home");
      } catch (e: any) {
        // userId: string, errorMessage: string,appointmentIdentifier: string,componentName: string
        logError(
          currentUser?.uid,
          `Error: ${e.code} - ${e.message}`,
          "Error in UserForm.tsx",
          "UserForm.tsx"
        ).catch(() => {
          console.log("Error occurred. Please try again later.");
        });
        setErrorMessage("Error submitting form. Please try again.");
      }
    }
  }

  function handleChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) {
    switch (event.target.name) {
      case "firstName":
        dispatch({
          type: "firstName",
          payload: event.target.value,
        });
        return;
      case "lastName":
        dispatch({
          type: "lastName",
          payload: event.target.value,
        });
        return;

      case "phoneNumber":
        dispatch({
          type: "phoneNumber",
          payload: event.target.value,
        });
        return;
      case "streetAddress":
        dispatch({
          type: "streetAddress",
          payload: event.target.value,
        });
        return;
      case "city":
        dispatch({
          type: "city",
          payload: event.target.value,
        });
        return;

      default:
        return;
    }
  }
  let zCode;
  if (!state.city) {
    zCode = "";
  } else {
    let zipValue = CityArray.findIndex((obj) => obj.city === state.city);
    zCode = CityArray[zipValue]["zip"];
  }

  // name fields in firebase don't like symbols like (-) hyphen for null check
  return (
    <div className="mb-3">
      {errorMessage ? (
        <ErrorNotification
          message={errorMessage}
          timeLength={TIME_LENGTH}
          setErrorMessage={setErrorMessage}
        />
      ) : null}
      <form className="border p-3" onSubmit={submitHandler}>
        <div className="row">
          <div className="col mb-2">
            <label htmlFor="firstName" className="form-label fw-bold">
              First Name
            </label>
            <input
              id="firstName"
              type="text"
              className="form-control"
              placeholder="First name"
              name="firstName"
              pattern="[A-Za-z'\-\s]{1,32}"
              required
              onChange={(e) => handleChange(e)}
              value={state.firstName}
            />
          </div>
          <div className="col mb-2">
            <label htmlFor="lastName" className="form-label fw-bold">
              Last Name
            </label>
            <input
              id="lastName"
              type="text"
              className="form-control"
              placeholder="Last name"
              pattern="[A-Za-z'\-\s]{1,32}"
              name="lastName"
              required
              onChange={(e) => handleChange(e)}
              value={state.lastName}
            />
          </div>
        </div>

        <div className="row">
  <div className="col mb-2">
    <label htmlFor="phoneNumber" className="form-label fw-bold">
      Phone Number (e.g., 999-999-9999 or 9999999999)
    </label>
    <input
      id="phoneNumber"
      type="tel"
      className="form-control"
      name="phoneNumber"
      pattern="^\d{3}-?\d{3}-?\d{4}$"
      placeholder="Format: 555-777-8888 or 5557778888"
      required
      onChange={(e) => handleChange(e)}
      value={state.phoneNumber}
    />
  </div>
</div>

        <div className="row">
          <div className="col mb-2">
            <label htmlFor="streetAddress" className="form-label fw-bold">
              Street Address
            </label>

            <input
              id="streetAddress"
              type="text"
              className="form-control"
              placeholder="Street Address"
              name="streetAddress"
              required
              onChange={(e) => handleChange(e)}
              value={state.streetAddress}
            />
          </div>
        </div>
        <div className="row">
          <div className="col mb-2">
            <label htmlFor="city" className="form-label fw-bold">
              City
            </label>
            <select
              id="city"
              name="city"
              required
              className="form-select form-select-md"
              aria-label="Form Select"
              onChange={(e) => handleChange(e)}
              //value={state.city}
              defaultValue={"Select a City"}
            >
              {CityArray.map((cityName: any) => (
                <option
                  key={cityName.id}
                  value={cityName.city}
                  disabled={cityName.id === 0 ? true : false}
                >
                  {cityName.city}
                </option>
              ))}
            </select>
          </div>
          <div className="col mb-2">
            <label htmlFor="zipCode" className="form-label fw-bold">
              Zip Code
            </label>
            <input
              id="zipCode"
              type="text"
              pattern="[0-9]{5}"
              title="Five digit zip code"
              className="form-control"
              placeholder="Zip Code"
              name="zipCode"
              required
              disabled
              value={zCode}
            />
          </div>
        </div>
        <div className="d-flex justify-content-center mt-2">
          <button
            type="submit"
            className="btn btn-primary d-flex align-items-center"
          >
            Save <AiOutlineSave style={styles.icon22} />
          </button>
        </div>
      </form>
      <AutoCloseModal showModal={showModal} setShowModal={setShowModal} />
    </div>
  );
}
