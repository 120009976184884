import React, { useState, useRef } from "react";
import { Container, Button, Table } from "react-bootstrap";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import LoadingIcon from "../components/LoadingIcon";
import { fetchSignInMethodsForEmail } from "firebase/auth";
import { auth, db } from "../firebase";
import { getEmailDocs } from "../functions/getEmailDocs";
import { deleteField, doc, getDoc, updateDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import logError from "../functions/logError";
import ErrorNotification from "../components/ErrorNotification";

const styles = {
  toastIcon: { fontSize: 20 },
  divEnabled: {
    pointerEvents: "auto" as React.CSSProperties["pointerEvents"],
  },
  divDisabled: {
    pointerEvents: "none" as React.CSSProperties["pointerEvents"],
  },
  icon22: { fontSize: 22, marginLeft: 2 },
};

const TIME_LENGTH = 5000;

export default function AdminPayment() {
  const [{ isPending, isRejected }] = usePayPalScriptReducer();
  const amountRef = useRef<string>(""); // this is an object with one property called current
  const [confirmed, setConfirmed] = useState(false);
  const emailRef = useRef<string>("");
  const appointmentIdRef = useRef<string>("");
  const [appointments, setAppointments] = useState<any[] | null>([]);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string | null>(null); // This will be used to display error messages to the User

  if (isRejected) {
    return <h1 className="text-center">Error loading PayPal scripts!</h1>;
  }

  async function handleDelete(id: string) {
    const docRefCompleted = doc(db, "completed", "past-appointments");
    const docRefAppointments = doc(db, "appointments", "scheduled");
    const docRefPaid = doc(db, "paid", "transactions");

    const identifier = appointmentIdRef.current;
    const docSnap = await getDoc(docRefAppointments);
    if (!docSnap.exists()) {
      logError("Admin", "No such document!", id, "AdminPayment.tsx").catch(
        (loggingError) => {
          // this is the catch block for the logError function which is a promise
          console.error("Error occurred while logging the error.");
        }
      );
      console.log("No such document!");
      setErrorMessage("An error occurred while deleting the appointment.");
      return;
    }

    const data = docSnap.get(identifier)?.isPaid;
    const paidRecord = docSnap.data()[identifier];
    if (data !== true) {
      return;
    }

    try {
      await updateDoc(docRefPaid, {
        [identifier]: paidRecord,
      });
      await updateDoc(docRefCompleted, {
        [id]: deleteField(),
      });
      await updateDoc(docRefAppointments, {
        [id]: deleteField(),
      });
      alert("Successfully deleted records!");
    } catch (error: any) {
      logError(
        "Administrator",
        `Error: ${error.name}, Message: ${error.message}, Stack: ${error.stack}`,
        id,
        "AdminPayment.tsx"
      ).catch((loggingError) => {
        // this is the catch block for the logError function which is a promise
        console.error("Error occurred while logging the error.");
      });
      setErrorMessage("An error occurred while deleting the appointment.");
    }
  }

  function emailHandler(e: any, email: string) {
    e.preventDefault();

    fetchSignInMethodsForEmail(auth, email)
      .then((signInMethods) => {
        if (signInMethods.length > 0) {
          // User exists
          alert("User exists!");
        } else {
          // User does not exist
          alert("User does not exist. Try again!");
        }
      })
      .catch((error) => {
        logError(
          "Admin",
          `Error: ${error.name}, Message: ${error.message}, Stack: ${error.stack}`,
          email,
          "AdminPayment.tsx"
        ).catch((loggingError) => {
          // this is the catch block for the logError function which is a promise
          console.error("Error occurred while logging the error.");
        });
        setErrorMessage("An error occurred while checking the email.");
      });
  }

  function getAppointments(e: any, userEmail: string) {
    e.preventDefault();
    async function getDocs() {
      const docRef = doc(db, "appointments", "scheduled");
      await getDoc(docRef).then((docSnap) => {
        if (docSnap.exists()) {
          let docData = docSnap.data();
          const appointments = getEmailDocs(userEmail, docData);
          setAppointments(appointments);
        }
      });
    }
    getDocs();
  }

  const onSaveHandler = () => {
    if (amountRef.current === "0" || amountRef.current === "") {
      alert("Please enter a valid amount.");
      return;
    }
    if (window.confirm(`Is $${amountRef.current} the correct amount?`)) {
      setConfirmed(true);
    } else {
      setConfirmed(false);
    }
  };

  const createOrder = async (data: any, actions: any) => {
    const orderAmount = parseFloat(amountRef.current).toFixed(2) || "1.00";
    return await actions.order.create({
      purchase_units: [
        {
          description: `${emailRef.current} # ${appointmentIdRef.current}`,
          amount: {
            value: orderAmount,
          },
        },
      ],
    });
  };

  const onApprove = async (data: any, actions: any) => {
    return await actions.order.capture().then(async (details: any) => {
      alert("Transaction completed by " + details.payer.name.given_name);
      emailRef.current = "";
      setConfirmed(false);
      amountRef.current = "";

      try {
        const docRef = doc(db, "appointments", "scheduled");
        const data = { [`${appointmentIdRef.current}.isPaid`]: true }; // the property isPaid is set to true

        await updateDoc(docRef, data); //data is an object
        await handleDelete(appointmentIdRef.current);
        alert("Appointment Paid! Thank you!");
        navigate("/admin");
      } catch (error: any) {
        logError(
          "Administrator",
          `Error: ${error.name}, Message: ${error.message}, Stack: ${error.stack}`,
          appointmentIdRef.current,
          "AdminPayment.tsx"
        ).catch((loggingError) => {
          // this is the catch block for the logError function which is a promise
          console.error("Error occurred while logging the error.");
        });
        setErrorMessage("Error during Approval Process");
      }
    });
  };

  const onError = (err: any) => {
    // For example, redirect to a specific error page
    logError(
      "Admin",
      `Error: ${err.name}, Message: ${err.message}, Stack: ${err.stack}`,
      appointmentIdRef.current,
      "AdminPayment.tsx"
    ).catch((loggingError) => {
      // this is the catch block for the logError function which is a promise
      console.error("Error occurred while logging the error.");
    });
    setErrorMessage("Error during Approval Process");
  };

  return (
    <>
      <Container>
        <div className="h1 text-center mb-3 fst-italic">Payment</div>
        {errorMessage ? (
          <ErrorNotification
            message={errorMessage}
            timeLength={TIME_LENGTH}
            setErrorMessage={setErrorMessage}
          />
        ) : null}
        <div className="h5 text-center">
          Email and Appointment ID will be entered in the description of PayPay
          transaction.
        </div>
        <div className="d-flex justify-content-center mb-3">
          <label htmlFor="email" className="me-2 fw-bold">Email:</label>
          <input
            id="email"
            type="text"
            placeholder="Enter Email"
            name="email"
            onChange={(e) => {
              emailRef.current = e.target.value;
            }}
          />
          <Button
            variant="warning"
            className="ms-1"
            onClick={(e) => emailHandler(e, emailRef.current)}
          >
            Check Email
          </Button>
        </div>
        <div className="d-flex justify-content-center mb-3">
          <Button
            variant="info"
            className="ms-1"
            onClick={(e) => getAppointments(e, emailRef.current)}
          >
            Get Appointments
          </Button>
        </div>
        <div className="h5 text-center">
          If no appointment exists have the user create one.
        </div>
        <div className="d-flex justify-content-center mb-3">
          <label htmlFor="appointmentId" className="me-2 fw-bold">Appointment ID:</label>
          <input
            id="appointmentId"
            type="text"
            placeholder="Enter Appointment ID"
            name="appointmentId"
            onChange={(e) => {
              appointmentIdRef.current = e.target.value;
            }}
          />
        </div>
        <div className="h2 fst-italic text-center mb-3">
          Make a Payment of ${amountRef.current}
        </div>
        <div className="fw-bold text-center">
          Only enter positive whole numbers. No decimals or special characters.
        </div>
        <div className="container d-flex justify-content-center mt-3 mb-3">
          <input
            id="amount"
            type="number"
            className="form-control w-25"
            placeholder="0"
            // value={state?.amount ? state.amount : amountRef.current}
            onChange={(e) => {
              const regEx = /^[0-9\b]+$/;
              e.target.value === "" || regEx.test(e.target.value)
                ? (amountRef.current = e.target.value)
                : (amountRef.current = "");
            }}
          />
        </div>

        <div className="container d-flex justify-content-center mt-3 mb-3">
          <Button variant="primary" onClick={onSaveHandler}>
            Confirm
          </Button>
        </div>

        <div
          className="container d-flex justify-content-center"
          style={confirmed ? styles.divEnabled : styles.divDisabled}
        >
          {isPending ? (
            <LoadingIcon />
          ) : (
            <PayPalButtons
              createOrder={createOrder}
              onApprove={onApprove}
              onError={onError}
            />
          )}
        </div>
      </Container>

      <Container>
        <Table responsive="md" striped bordered hover size="sm">
          <thead>
            <tr>
              <th>#</th>
              <th>AppointmentID</th>
              <th>Date</th>
              <th>Time</th>

              <th>Description</th>
              <th>Visit Type</th>
              <th>Done</th>
              <th>Paid</th>
              <th>Cost</th>
            </tr>
          </thead>
          <tbody>
            {!appointments
              ? null
              : appointments.map((item: any, index: number) => {
                  let text = item[1].date;
                  let year = text.slice(0, 4);
                  let month = text.slice(4, 6);
                  let day = text.slice(6, 8);
                  let formattedDate = `${month}/${day}/${year}`;

                  return (
                    <tr key={item[0] as string}>
                      <td>{index + 1}</td>
                      <td>{item[0]}</td>
                      <td>{formattedDate}</td>

                      <td>{item[1].time}</td>

                      <td>{item[1].description}</td>
                      <td className="text-nowrap">
                        {item[1].isRemote === true ? "Remote" : "In-Person"}
                      </td>

                      <td>{item[1].isCompleted ? "Yes" : "No"}</td>
                      <td>{item[1].isPaid.toString()}</td>
                      <td>${item[1].amount}</td>
                    </tr>
                  );
                })}
          </tbody>
        </Table>
      </Container>
    </>
  );
}
