import React from "react";
import UserForm from "../components/UserForm";
import { Container, Image } from "react-bootstrap";
import Island from "../images/island.png";
import { useNavigate } from "react-router-dom";

export default function ServiceForm() {
  const navigate = useNavigate();
  return (
    <Container>
      <div className="h1 text-center fst-italic">Please complete Service Form</div>
      <div className="h4 text-center fst-italic">Service Area</div>
      <div className="d-flex justify-content-center">
        <Image src={Island} fluid className="w-50" alt="Long Island service area."/>
      </div>
      <div className="h6 container text-center">
        We provide service to the Town of Islip and the Town of Smithtown.
      </div>
      <div className="h6 container text-center">
        You must only provide an address that is within our service area.
      </div>
      <div className="h4 text-center fst-italic">Service Form</div>
      <div className="d-flex justify-content-center">
        <UserForm navigate={navigate} />
      </div>
    </Container>
  );
}
