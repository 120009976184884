import React from "react";
import { Container, Image } from "react-bootstrap";
import { FaChrome, FaWindows } from "react-icons/fa";
import SupportPic from "../images/remoteSupport.jpeg";

const styles = {
  icon24: { fontSize: 24, marginLeft: 4 },
};

export default function RemoteSupport() {
  return (
    <Container>
      <div className="h1 text-center fst-italic">Remote Support</div>
      <div className="d-flex justify-content-center">
        <Image src={SupportPic} fluid className="w-25" alt="Support agent working on a computer."/>
      </div>
      <div className="d-flex justify-content-center">
        <p className="text-wrap text-break mt-2 w-50 text-center">
          Remote Support allows a technician to guide the user or take full
          control of their device once the program is installed and a code has
          been shared.
        </p>
      </div>
      <div className="h6 text-center text-primary fst-italic">
        Choose a method of remote support based on your Operating System.
      </div>
      <div>
        <div className="h6 text-center">
          Works for Windows, Mac, or Chromebook.
        </div>
        <div className="d-flex justify-content-center">
          <a
            href="https://remotedesktop.google.com/home"
            target="_blank"
            rel="external noreferrer noopener"
            className="h6 text-center bg-success text-light py-2 w-75 text-decoration-none d-flex align-items-center justify-content-center text-nowrap"
          >
            Chrome Remote Desktop <FaChrome style={styles.icon24} />
          </a>
        </div>
        <div className="h6 text-center">
          Works only for Windows but it is more capable than the other solution.
        </div>
        <div className="d-flex justify-content-center mb-4">
          <a
            href="https://apps.microsoft.com/detail/9P7BP5VNWKX5?launch=true&mode=full&referrer=bingwebsearch&ocid=bingwebsearch&hl=en-us&gl=US"
            target="_blank"
            rel="external noreferrer noopener"
            className="h6 text-center bg-warning py-2 w-75 text-decoration-none d-flex align-items-center justify-content-center text-nowrap"
          >
            Quick Assist <FaWindows style={styles.icon24} />
          </a>
        </div>
      </div>
    </Container>
  );
}
