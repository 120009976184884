import React, { type ReactNode, createContext, useContext } from "react";
import { useAuth } from "./useAuth";

// Create a context object
const FirebaseContext = createContext<any | null>(null);

// Create a custom hook to use the context
export function useAuthContext() {
  return useContext(FirebaseContext);
}

type FirebaseProviderProps = {
  children: ReactNode;
};

// Create a provider component that will wrap the part of the app that needs access to the context
export function FirebaseProvider({ children }: FirebaseProviderProps) {
  const auth = useAuth();

  return (
    <FirebaseContext.Provider value={auth}>{children}</FirebaseContext.Provider>
  );
}
