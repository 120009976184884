import React, { useState } from "react";
import { Container } from "react-bootstrap";
import {
  verifyPasswordResetCode,
  confirmPasswordReset,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { useAuthContext } from "../components/AuthContext";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";
import { AiOutlineSave } from "react-icons/ai";
import ErrorNotification from "../components/ErrorNotification";
import logError from "../functions/logError";
import AutoCloseModal from "../components/AutoCloseModal";

const styles = {
  icon22: {
    fontSize: 22,
  },
};

const TIME_LENGTH = 5000;

export default function PasswordReset({
  actionCode,
}: {
  actionCode: string | null;
}) {
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirmPassword] = useState("");
  const navigate = useNavigate();
  const { logout } = useAuthContext();

  const [errorMessage, setErrorMessage] = useState<string | null>(null); // This will be used to display error messages to the User
  const [showModal, setShowModal] = useState({  // This is used for the AutoCloseModal component
    show: false,
    header: "",
    body: "",
  });

  function checkPasswords(password: string, confirm_password: string) {
    if (password.toLocaleLowerCase() !== confirm_password.toLocaleLowerCase()) {
      
      setShowModal({
        show: true,
        header: "Oops!",
        body: "Passwords do not match, please try again.",
      });
      return false;
    }
    return true;
  }

  function handleResetPassword(auth: any, actionCode: string) {
    // Verify the password reset code is valid.
    verifyPasswordResetCode(auth, actionCode)
      .then((email) => {
        const accountEmail = email;

        // Save the new password.
        confirmPasswordReset(auth, actionCode, password)
          .then((resp) => {
            // Password reset has been confirmed and new password updated.

            // if the page belongs to the same domain as the app:
            signInWithEmailAndPassword(auth, accountEmail, password);
          })
          .catch((error) => {
            // Error occurred during confirmation. The code might have expired or the
            // password is too weak.
            setErrorMessage(
              "Password is too weak or too much time has passed. Error occurred: "
            );
            logError(
              "No user id",
              `Error: ${error.name}, Message: ${error.message}, Stack: ${error.stack}`,
              "No appointment identifier",
              "PasswordReset.tsx"
            ).catch((loggingError) => {
              // this is the catch block for the logError function which is a promise
              console.error("Error occurred while logging the error.");
            });
          });
      })
      .catch((error) => {
        // Invalid or expired action code. Ask user to try to reset the password
        // again.
        setErrorMessage(
          "Please try to reset the password again.  Error occured."
        );
        logError(
          "No user id",
          `Error: ${error.name}, Message: ${error.message}, Stack: ${error.stack}`,
          "No appointment identifier",
          "PasswordReset.tsx"
        ).catch((loggingError) => {
          // this is the catch block for the logError function which is a promise
          console.error("Error occurred while logging the error.");
        });
      });
  }

  function onSubmitHandler(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (checkPasswords(password, confirm_password)) {
      if (!actionCode) {
        return;
      }
      handleResetPassword(auth, actionCode);
      
      setShowModal({
        show: true,
        header: "Success!",
        body: "Password has been reset!",
      });
      try {
        async function logoutFunc() {
          await logout();
        }
        logoutFunc();
      } catch (error: any) {
        // This will render an error message to the user from the navbar > passed through context
        setErrorMessage("An error occurred while logging out.");
        logError(
          "No user id",
          `Error: ${error.name}, Message: ${error.message}, Stack: ${error.stack}`,
          "No appointment identifier",
          "PasswordReset.tsx"
        ).catch((loggingError) => {
          // this is the catch block for the logError function which is a promise
          console.error("Error occurred while logging the error.");
        });
      } finally {
        navigate("/login");
      }
    }
  }

  return (
    <Container>
      <div className="h1 text-center fst-italic">Password Reset</div>
      {
        // This will render an error message to the user from the navbar > passed through context
        errorMessage ? (
          <ErrorNotification
            message={errorMessage}
            timeLength={TIME_LENGTH}
            setErrorMessage={setErrorMessage}
          />
        ) : null
      }
      <div className="d-flex justify-content-center">
        <form onSubmit={onSubmitHandler}>
          <label htmlFor="password" className="col-form-label-sm fw-bold">
            Password
          </label>
          <input
            id="password"
            type="password"
            name="password"
            pattern=".{8,}"
            className="form-control"
            required
            placeholder="Password (min 8 characters)"
            minLength={8}
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />

          <label
            htmlFor="confirm_password"
            className="col-form-label-sm fw-bold"
          >
            Repeat Password
          </label>
          <input
            id="confirm_password"
            type="password"
            name="confirm_password"
            className="form-control"
            pattern=".{8,}"
            required
            placeholder="Confirm Password"
            minLength={8}
            onChange={(e) => setConfirmPassword(e.target.value)}
            value={confirm_password}
          />

          <button className="btn btn-primary mt-3" type="submit">
            Save <AiOutlineSave style={styles.icon22} />
          </button>

          <br />
        </form>
      </div>
      <AutoCloseModal showModal={showModal} setShowModal={setShowModal} />
    </Container>
  );
}
