import React, { useEffect, useRef } from "react";
import { Alert } from "react-bootstrap";

export default function ErrorNotification({
  header = "Attention!",
  message = "",
  timeLength = 5000,
  setErrorMessage,
}: {
  // ? means optional
  header?: string;
  message: string | null;
  timeLength?: number;
  setErrorMessage: any;
}) {
  const timeoutId = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (message) {
      // setTimeout() returns a number, so we need to store it in a variable
      timeoutId.current = setTimeout(() => {
        setErrorMessage(null);
      }, timeLength);
    }

    return () => {
      if (timeoutId.current) {
        // if timeoutId.current is not null
        clearTimeout(timeoutId.current);
      }
    };
  }, [message, timeLength, setErrorMessage]);

  return (
    <>
      <Alert
        variant="danger"
        className="mt-2 text-center"
        onClose={() => {
          // this executes when the user clicks the close button
          if (timeoutId.current) {
            clearTimeout(timeoutId.current);
          }
          setErrorMessage(null);
        }}
        dismissible
      >
        <Alert.Heading>{header}</Alert.Heading>
        <p>{message}</p>
      </Alert>
    </>
  );
}
