import logError from "./logError";


export const GetUserObjs = (currentUser, docSnap) => {
  const userAppointments = [];
  try {
    // Here we filter the data to only show the user's appointments
    Object.keys(docSnap).forEach((key) => {
      if (
        typeof docSnap[key] === "object" &&
        docSnap[key].hasOwnProperty("userId")
      ) {
        const userString = docSnap[key].userId;

        if (userString === currentUser?.uid) {
          userAppointments.push([key, docSnap[key]]);
        }
      }
    });

    let userAppointmentsSorted = userAppointments.sort((a, b) => {
      return a[1].dateAndTime.seconds - b[1].dateAndTime.seconds;
    });
    return userAppointmentsSorted;
  } catch (error) {
    // doc.data() will be undefined in this case
    logError(
      currentUser?.uid,
      `Error: ${error.name} ${error.message} ${error.stack}`,
      "No appointment data",
      "GetUserObjs.js"
    ).then((result) => {
      console.log("Error logged to database");
    });
    return null;
  }
};
