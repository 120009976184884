import React, { useReducer } from "react";
import { CityArray } from "../components/SuffolkHamlets";
import { doc } from "firebase/firestore";
import { useAuthContext } from "../components/AuthContext";
import { Container, Button } from "react-bootstrap";
import { UpdateDocData } from "../functions/UpdateDocData";
import { db, auth } from "../firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import { Link } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { BiReset } from "react-icons/bi";
import { MdOutlineMarkEmailRead } from "react-icons/md";
import { AiOutlineSave } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import ErrorNotification from "../components/ErrorNotification";
import logError from "../functions/logError";
import AutoCloseModal from "../components/AutoCloseModal";

//dispatch function is the action argument passed to reducer function
function reducer(state: any, action: any) {
  switch (action.type) {
    case "firstName":
      return { ...state, firstName: action.payload };
    case "lastName":
      return { ...state, lastName: action.payload };
    case "phoneNumber":
      return { ...state, phoneNumber: action.payload };
    case "streetAddress":
      return { ...state, streetAddress: action.payload };
    case "city":
      return { ...state, city: action.payload };

    default:
      return state;
  }
}

const renderTooltip = (props: any) => (
  <Tooltip id="button-tooltip" {...props}>
    Please verify your email first.
  </Tooltip>
);

const styles = {
  icon22: { fontSize: 22, marginLeft: 2 },
};

const TIME_LENGTH = 5000;

export default function Account() {
  const { currentUser, currentUserData, logout } = useAuthContext();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null); // This will render on the Navbar component because on useContext

  const [state, dispatch] = useReducer(reducer, {
    firstName: currentUserData?.firstName,
    lastName: currentUserData?.lastName,
    phoneNumber: currentUserData?.phoneNumber,
    streetAddress: currentUserData?.streetAddress,
    city: currentUserData?.city,
  });

  const [showModal, setShowModal] = React.useState({
    show: false,
    header: "",
    body: "",
  });

  async function submitHandler(e: any) {
    e.preventDefault();
    if (window.confirm("Are you sure you want to change your information? If YES select OK")) {
      const docData = {
        "firstName": state.firstName,
        "lastName": state.lastName,
        "phoneNumber": state.phoneNumber,
        "streetAddress": state.streetAddress,
        "city": state.city,
        "zipCode": e.target.zipCode.value,
      };
      if (!currentUser) {
        return;
      } else {
        const docRef = doc(db, "users", currentUser.uid);
        const docBoolean = UpdateDocData(docRef, docData);
        if (docBoolean) {
          setShowModal({
            show: true,
            header: "Success!",
            body: "Your information has been updated.",
          });
        }
      }
    }
  }

  //let cityObj = CityArray.find(obj => obj.city === event.target.value);
  function handleChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) {
    switch (event.target.name) {
      case "firstName":
        dispatch({
          type: "firstName",
          payload: event.target.value,
        });
        return;
      case "lastName":
        dispatch({
          type: "lastName",
          payload: event.target.value,
        });
        return;
      case "phoneNumber":
        dispatch({
          type: "phoneNumber",
          payload: event.target.value,
        });
        return;
      case "streetAddress":
        dispatch({
          type: "streetAddress",
          payload: event.target.value,
        });
        return;
      case "city":
        dispatch({
          type: "city",
          payload: event.target.value,
        });
        return;

      default:
        return;
    }
  }

  let zCode;
  if (!state.city) {
    zCode = "";
  } else {
    let zipValue = CityArray.findIndex((obj) => obj.city === state.city);
    zCode = CityArray[zipValue]["zip"];
  }

  function requestPasswordReset() {
    const email = currentUser?.email;
    sendPasswordResetEmail(auth, email)
      .then(async () => {
        // Password reset email sent!
        
        setShowModal({
          show: true,
          header: "Success!",
          body: "Password Reset email sent!",
        });
        try {
          await logout();
        } catch (error: any) {
          // This will render on the Navbar component because on useContext
          setErrorMessage("An error occurred while logging out.");
          logError(
            currentUser?.uid,
            `Error: ${error.name}, Message: ${error.message}, Stack: ${error.stack}`,
            "No appointment data",
            "Account.tsx"
          ).then((result) => {
            console.log("Error logged to database");
          });
        } finally {
          navigate("/login");
        }
      })
      .catch((error) => {
      setErrorMessage("Error, please logout and try again.");
      });
  }

  if (
    currentUserData?.zipCode === null ||
    currentUserData?.zipCode === undefined
  ) {
    return (
      <div>
        <Navigate to="/serviceform" />
      </div>
    );
  } else {
    return (
      <Container className="mb-3">
        <div className="h1 text-center fst-italic">Account Information</div>
        {errorMessage ? (
          <ErrorNotification
            message={errorMessage}
            timeLength={TIME_LENGTH}
            setErrorMessage={setErrorMessage}
          />
        ) : null}
        {currentUser?.emailVerified === true ? (
          <div>
            <div className="d-flex justify-content-center mb-2">
              <Link
                className="btn btn-success d-flex align-items-center justify-content-center"
                to="/emailchange"
              >
                Change Email <MdOutlineMarkEmailRead style={styles.icon22} />
              </Link>
            </div>
            <div className="d-flex justify-content-center mb-2">
              <Button
                variant="primary"
                onClick={requestPasswordReset}
                className="d-flex align-items-center justify-content-center"
              >
                Reset Password <BiReset style={styles.icon22} />
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <div className="d-flex justify-content-center mb-2">
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <Button className="btn btn-success d-flex align-items-center justify-content-center">
                  Change Email <MdOutlineMarkEmailRead style={styles.icon22} />
                </Button>
              </OverlayTrigger>
            </div>
            <div className="d-flex justify-content-center mb-2">
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
              >
                <Button
                  variant="primary"
                  className="d-flex align-items-center justify-content-center"
                >
                  Reset Password <BiReset style={styles.icon22} />
                </Button>
              </OverlayTrigger>
            </div>
          </div>
        )}
        <div className="h3 text-center fst-italic">
          Change User Data and Service Address
        </div>
        <div className="d-flex justify-content-center">
          <form className="border p-2 w-75" onSubmit={submitHandler}>
            <div className="row">
              <div className="col mb-2">
                <label htmlFor="firstName" className="form-label fw-bold">
                  First Name
                </label>
                <input
                  id="firstName"
                  type="text"
                  className="form-control"
                  placeholder="First name"
                  name="firstName"
                  pattern="[A-Za-z'\-\s]{1,32}"
                  required
                  onChange={(e) => handleChange(e)}
                  value={state.firstName}
                />
              </div>
              <div className="col mb-2">
                <label htmlFor="lastName" className="form-label fw-bold">
                  Last Name
                </label>
                <input
                  id="lastName"
                  type="text"
                  className="form-control"
                  placeholder="Last name"
                  name="lastName"
                  pattern="[A-Za-z'\-\s]{1,32}"
                  required
                  onChange={(e) => handleChange(e)}
                  value={state.lastName}
                />
              </div>
            </div>
            <div className="row">
  <div className="col mb-2">
    <label htmlFor="phoneNumber" className="form-label fw-bold">
      Phone Number (e.g., 999-999-9999 or 9999999999)
    </label>
    <input
      id="phoneNumber"
      type="tel"
      className="form-control"
      name="phoneNumber"
      pattern="^\d{3}-?\d{3}-?\d{4}$"
      placeholder="Format: 555-777-8888 or 5557778888"
      required
      onChange={(e) => handleChange(e)}
      value={state.phoneNumber}
    />
  </div>
</div>

            <div className="row">
              <div className="col mb-2">
                <label htmlFor="streetAddress" className="form-label fw-bold">
                  Street Address
                </label>
                <input
                  id="streetAddress"
                  type="text"
                  className="form-control"
                  placeholder="Street Address"
                  name="streetAddress"
                  required
                  onChange={(e) => handleChange(e)}
                  value={state.streetAddress}
                />
              </div>
            </div>
            <div className="row">
              <div className="col mb-2">
                <label htmlFor="city" className="form-label fw-bold">
                  City
                </label>
                <select
                  id="city"
                  name="city"
                  required
                  className="form-select form-select-md"
                  aria-label="Form Select"
                  onChange={(e) => handleChange(e)}
                  value={state.city} //this is like defaultValue but for select
                >
                  {CityArray.map((cityName: any) => (
                    <option
                      key={cityName.id}
                      value={cityName.city}
                      disabled={cityName.id === 0 ? true : false}
                    >
                      {cityName.city}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col mb-2">
                <label htmlFor="zipCode" className="form-label fw-bold">
                  Zip Code
                </label>
                <input
                  id="zipCode"
                  type="text"
                  pattern="[0-9]{5}"
                  title="Five digit zip code"
                  className="form-control"
                  placeholder="Zip Code"
                  name="zipCode"
                  required
                  disabled
                  value={zCode}
                />
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <button
                type="submit"
                className="btn btn-info d-flex align-items-center justify-content-center"
              >
                Save <AiOutlineSave style={styles.icon22} />
              </button>
            </div>
          </form>
        </div>
        <AutoCloseModal showModal={showModal} setShowModal={setShowModal} />
      </Container>
    );
  }
}
