import { useState } from "react";
import Calendar from "react-calendar";
import Times from "./Times";
import { Container } from "react-bootstrap";
import { Timestamp } from "firebase/firestore";
import { FaHandPointer } from "react-icons/fa6";
import "react-calendar/dist/Calendar.css";
import "../CalendarApp.css"; // This overrides the default styling of react-calendar

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];

export default function CalendarAddIn({ handleToast }: { handleToast: any }) {
  const [date, setDate] = useState<Value>(new Date());
  const [showTime, setShowTime] = useState(false);
  // to select range at selectRange={true}
  const timestamp = Timestamp.fromDate(date as Date);
  const hourOffset = timestamp.toDate().getTimezoneOffset() / 60;

  // This function disables the days of the week that are not available for appointments
  const tileDisabled = ({ date }: { date: Date }) => {
    //var futureDate = new Date(date.setMonth(date.getMonth() + 3));
    //const day = new Date().getDate();
    const today = new Date();

    return (
      date.getDay() === 1 ||
      date.getDay() === 2 ||
      date.getDay() === 3 ||
      date.getDay() === 4 ||
      date.getDay() === 5 ||
      date < today
    );
  };

  return (
    <Container>
      <Container className="h4 d-flex justify-content-center align-items-center fst-italic">
        Select a Date & Time
        <FaHandPointer className="ms-1" />
      </Container>
      <div className="d-flex justify-content-center">
        <span className="fst-italic h5 bg-warning mb-1 p-1 rounded text-center">
          Each user is limited to (3) three appointments. Current availability is Weekends.
        </span>
      </div>
      <div className="d-flex justify-content-center">
        <Calendar
          onChange={setDate}
          value={date}
          onClickDay={() => {
            setShowTime(true);
            handleToast();
          }}
          tileDisabled={tileDisabled}
          nextLabel="next month >>"
          nextAriaLabel="Go to next month"
          next2Label={null}
          prevLabel="<< prev month"
          prevAriaLabel="Go to prev month"
          prev2Label={null}
        />
      </div>
      <div className="d-flex justify-content-center">
        <Times
          showTime={showTime}
          date={date}
          hourChange={hourOffset}
          setShowTime={setShowTime}
        />
      </div>
    </Container>
  );
}
