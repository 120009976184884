import React, { type ReactNode } from "react";
import { useAuthContext } from "./AuthContext";
import { Navigate } from "react-router-dom";

type AdminRouteProps = {
  element: ReactNode;
};

const AdminProtectedRoute = ({ element }: AdminRouteProps) => {
  const { currentUser, currentUserData } = useAuthContext();
  if (!currentUser && !currentUserData?.isAdmin) {
    return (
      <div>
        <div className="h1 text-center">Not Authorized</div>
        <Navigate to="/login" />
      </div>
    );
  } else {
    return <div>{element}</div>;
  }
};

export default AdminProtectedRoute;
