import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { GrSend } from "react-icons/gr";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";
import { sendPasswordResetEmail } from "firebase/auth";
import { useAuthContext } from "../components/AuthContext";
import ErrorNotification from "../components/ErrorNotification";
import logError from "../functions/logError";
import AutoCloseModal from "../components/AutoCloseModal";

const styles = {
  icon22: {
    fontSize: 22,
    marginLeft: 2,
  },
};

const TIME_LENGTH = 5000;

export default function ForgotPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const { logout } = useAuthContext();
  const [errorMessage, setErrorMessage] = useState<string | null>(null); // This will be used to display error messages to the User
  const [showModal, setShowModal] = useState({  // This is used for the AutoCloseModal component  
    show: false,
    header: "",
    body: "",
  });

  async function onSubmitHandler(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    await sendPasswordResetEmail(auth, email)
      .then(async () => {
        // Password reset email sent!
        
        setShowModal({
          show: true,
          header: "Success!",
          body: "Password reset email has been sent!",
        });
        try {
          await logout();
        } catch (error: any) {
          // This will render an error message to the user from the navbar > passed through context
          setErrorMessage("An error occurred while logging out.");
          logError(
            "No user id",
            `Error: ${error.name}, Message: ${error.message}, Stack: ${error.stack}`,
            "No appointment identifier",
            "ForgotPassword.tsx"
          ).catch((loggingError) => {
            // this is the catch block for the logError function which is a promise
            console.error("Error occurred while logging the error.");
          });

          // After 5 seconds, clear the error
        } finally {
          navigate("/login");
        }
        setEmail("");
        navigate("/login");
      })
      .catch((error) => {
        setErrorMessage("An error occurred while sending the password reset.");
        logError(
          "No user id",
          `Error: ${error.name}, Message: ${error.message}, Stack: ${error.stack}`,
          "No appointment identifier",
          "ForgotPassword.tsx"
        ).catch((loggingError) => {
          // this is the catch block for the logError function which is a promise
          console.error("Error occurred while logging the error.");
        });
        setEmail("");
      });
  }
  return (
    <Container>
      {
        // This will render an error message to the user from the navbar > passed through context
        errorMessage ? (
          <ErrorNotification
            message={errorMessage}
            timeLength={TIME_LENGTH}
            setErrorMessage={setErrorMessage}
          />
        ) : null
      }
      <div className="h1 text-center fst-italic">Password Reset</div>
      <div className="h6 text-center text-primary fst-italic">
        Please check your email for the password reset and click the link.
      </div>
      <Container className="d-flex justify-content-center">
        <form onSubmit={onSubmitHandler}>
          <label htmlFor="email" className="col-form-label-sm fw-bold">
            Email address
          </label>
          <input
            id="email"
            type="email"
            name="email"
            pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
            className="form-control"
            required
            placeholder="Email Address"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />

          <div className="d-flex justify-content-center">
            <button
              className="btn btn-info mt-3 d-flex align-items-center fw-bold"
              type="submit"
            >
              Send <GrSend style={styles.icon22} />
            </button>
          </div>

          <br />
        </form>
      </Container>
      <AutoCloseModal showModal={showModal} setShowModal={setShowModal} />
    </Container>
  );
}
