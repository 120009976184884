export const CityArray = [
  { id: 0, city: "Select a City", zip: 0 },
  { id: 1, city: "BAY SHORE", zip: 11706 },
  { id: 2, city: "BAYPORT", zip: 11705 },
  { id: 3, city: "BAYWOOD", zip: 11706 },
  { id: 4, city: "BOHEMIA", zip: 11716 },
  { id: 5, city: "BRENTWOOD", zip: 11717 },
  { id: 6, city: "CENTRAL ISLIP", zip: 11722 },
   { id: 7, city: "COMMACK", zip: 11725 },
  { id: 8, city: "EAST ISLIP", zip: 11730 },
  { id: 9, city: "GREAT RIVER", zip: 11739 },
   { id: 10, city: "HAUPPAUGE", zip: 11788},
  { id: 11, city: "HOLBROOK", zip: 11741 },
  { id: 12, city: "HOLTSVILLE", zip: 11742 },
  { id: 13, city: "ISLIP", zip: 11751 },
  { id: 14, city: "ISLIP TERRACE", zip: 11752 },
  { id: 15, city: "KINGS PARK", zip: 11754 },
  { id: 16, city: "LAKE RONKONKOMA", zip: 11779 },
  { id: 17, city: "NESCONSET", zip: 11767 },
  { id: 18, city: "NORTH GREAT RIVER", zip: 11752 },
  { id: 19, city: "OAKDALE", zip: 11769 },
 { id: 20, city: "RONKONKOMA", zip: 11779 },
  { id: 21, city: "SAINT JAMES", zip: 11780 },
  { id: 22, city: "SAYVILLE", zip: 11782 },
  { id: 23, city: "SMITHTOWN", zip: 11787 },
  { id: 24, city: "WEST ISLIP", zip: 11795 },
  { id: 25, city: "WEST SAYVILLE", zip: 11796 },
];
