import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase";

async function logError(
  userId: string,
  errorMessage: string,
  appointmentIdentifier: string,
  componentName: string
) {
  try {
    const errorTableRef = doc(db, "errorTable", "errors");

    const date = new Date();
    // Create a unique ID for the error
    const errorId = date.getTime().toString();
    const day = date.toLocaleDateString();
    const time = date.toLocaleTimeString();

    // Create the error object
    const errorObject = {
      [errorId]: {
        day,
        time,
        userId,
        errorMessage,
        appointmentIdentifier,
        componentName,
      },
    };

    // Update the errorTable document with the new error
    await setDoc(errorTableRef, errorObject, { merge: true });
  } catch (e) {
    console.error("Error occurred while logging the error.");
    throw e;
  }
}

export default logError;
