import React from "react";
import { useAuthContext } from "../components/AuthContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CalendarAddIn from "../components/CalendarAddIn";
import { Container } from "react-bootstrap";
import { VscVerifiedFilled } from "react-icons/vsc";
import AppointmentTable from "../components/AppointmentTable";
import { FaHandPointer } from "react-icons/fa";
import { Navigate } from "react-router-dom";
import LoadingIcon from "../components/LoadingIcon";

const styles = {
  toastIcon: { fontSize: 20 },
  divEnabled: {
    pointerEvents: "auto" as React.CSSProperties["pointerEvents"],
  },
  divDisabled: {
    pointerEvents: "none" as React.CSSProperties["pointerEvents"],
  },
  icon22: { fontSize: 22, marginLeft: 2 },
};

export default function Home() {
  const { currentUser, currentUserData, userObjs, loading } = useAuthContext();

  function handleToast() {
    toast.error("Select a Time to Confirm!", {
      position: "bottom-right",
      autoClose: 1750,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      icon: <FaHandPointer className="ms-1" />,
      style: styles.toastIcon,
    });
  }

  // Using ? helps it not crash when the user is null then when currentUserData is updated it will render the page
  // currentUser undefined if the document doesn't exist and null if still initial state
  if (loading) {
    // loading comes from the useAuthContext hook
    return (
      <div className="d-flex justify-content-center">
        <LoadingIcon />
      </div>
    );
  } else if (currentUserData === undefined) {
    return (
      <div>
        <Navigate to="/serviceform" />
      </div>
    );
  } else {
    return (
      <Container className="d-flex flex-column">
        <ToastContainer
          position="bottom-right"
          autoClose={1750}
          limit={1}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />

        <Container>
          <div className="row mt-2">
            <div className="col-md-6 order-1 d-flex justify-content-center">
              <span className="h6 fst-italic">
                Welcome, {currentUser?.email}
                <span className="fw-bold badge rounded-pill text-bg-info ms-1">
                  Verified <VscVerifiedFilled />
                </span>
              </span>
            </div>
            <div className="h6 fst-italic col-md-6 order-2 d-flex justify-content-center">
              <span className="text-primary me-1">Questions?</span>
              call (631) 482-7627
            </div>
          </div>
        </Container>

        <Container>
          <Container
            className="d-flex justify-content-center"
            style={
              userObjs !== null && userObjs.length >= 3
                ? styles.divDisabled
                : styles.divEnabled
            }
          >
            <CalendarAddIn handleToast={handleToast} />
          </Container>
          <AppointmentTable />
        </Container>
      </Container>
    );
  }
}
