import React, { useState, useEffect } from "react";
import {
  Modal,
  Form,
  FormGroup,
  FormLabel,
  FormControl,
  Button,
  Container,
} from "react-bootstrap";
import { doc, setDoc } from "firebase/firestore";
import { db } from "../firebase";
import { UpdateDocData } from "../functions/UpdateDocData";
import { useGetInfo } from "../functions/useGetInfo";
import logError from "../functions/logError";

export default function AdminModal({
  modalObject,
  setModalObject,
}: //handleDelete,
{
  modalObject: any;
  setModalObject: any;
  //handleDelete: any;
}) {
  const [show, setShow] = useState(false);
  const [data, setData] = useState<any>({
    description: "",
    notes: "",
    isCompleted: false,
    isPaid: false,
    isRemote: false,
    reference: "",
    amount: "",
  });

  useEffect(() => {
    setShow(modalObject?.modalShow);
    setData(modalObject?.obj);
  }, [modalObject]);

  const objId = modalObject.obj.userId;
  const info = useGetInfo(objId);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    switch (event.target.name) {
      case "description":
        setData({ ...data, description: event.target.value });
        return;
      case "notes":
        setData({ ...data, notes: event.target.value });
        return;

      case "isCompleted":
        setData({ ...data, isCompleted: event.target.checked });
        return;
      case "isPaid":
        setData({ ...data, isPaid: event.target.checked });
        return;
      case "isRemote":
        setData({ ...data, isRemote: event.target.checked });
        return;
      case "amount":
        setData({ ...data, amount: event.target.value });
        return;

      default:
        return;
    }
  }

  function handleSubmit(event: React.ChangeEvent<HTMLFormElement>) {
    event.preventDefault();
    const docData = {
      [`${modalObject.appointmentId}.description`]: data.description,
      [`${modalObject.appointmentId}.notes`]: data.notes,
      [`${modalObject.appointmentId}.isCompleted`]: data.isCompleted,
      [`${modalObject.appointmentId}.isPaid`]: data.isPaid,
      [`${modalObject.appointmentId}.isRemote`]: data.isRemote,
      [`${modalObject.appointmentId}.amount`]: data.amount,
    };

    try {
      const docRef = doc(db, "appointments", "scheduled"); //The last argument is an object
      UpdateDocData(docRef, docData);
      alert("Appointment Updated in scheduled collection.");

      if (data.isCompleted === true) {
        const DocRef = doc(db, "completed", "past-appointments");
        try {
          async function SetData() {
            await setDoc(
              DocRef,
              {
                [modalObject.appointmentId]: data,
              },
              { merge: true }
            );
          }
          SetData();
          alert("Appointment created in completed collection.");
          //handleDelete(modalObject.appointmentId);
        } catch (error: unknown) {
          // In JavaScript, any value can be thrown, not just Error objects.
          alert(
            "Error completing appointment - an entry on the error table has been created."
          );
          if (error instanceof Error) {
            // Type guard to check if error is an instance of Error
            // The arguments fof logout are (userId: string, errorMessage: string, appointmentIdentifier: string, componentName: string)
            logError(
              "admin",
              `Error: ${error.name}, Message: ${error.message}, Stack: ${error.stack}`,
              modalObject.appointmentId,
              "AdminModal.tsx"
            ).catch((loggingError) => {
              // this is the catch block for the logError function which is a promise
              console.error("Error occurred while logging the error.");
            });
          }
        }
      }
      handleClose();
    } catch (error: unknown) {
      if (error instanceof Error) {
        // Type guard to check if error is an instance of Error
        // The arguments fof logout are (userId: string, errorMessage: string, appointmentIdentifier: string, componentName: string)
        logError(
          "admin",
          `Error: ${error.name}, Message: ${error.message}, Stack: ${error.stack}`,
          modalObject.appointmentId,
          "AdminModal.tsx"
        ).catch((loggingError) => {
          // this is the catch block for the logError function which is a promise
          console.log("Error occurred while logging the error.");
        });
      }
      alert(
        "Error updating appointment - an entry on the error table has been created."
      );
    }
  }

  const handleClose = () => {
    setModalObject({ modalShow: false, appointmentId: "", obj: {} });
    setShow(false);
  };

  function formatDate(date?: string) {
    let text = date;
    let year = text?.slice(0, 4);
    let month = text?.slice(4, 6);
    let day = text?.slice(6, 8);
    let formattedDate = `${month}/${day}/${year}`;
    return formattedDate;
  }

  //controlId prop creates an id attribute on the <FormControl> component and a 'for' attribute on the <FormLabel> component.
  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title className="text-center">User Appointment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <div className="h6 text-center">{formatDate(data.date)}</div>
          <div className="h6 text-center">{data.time}</div>
          <ul className="list-group">
            <li className="list-group-item d-flex justify-content-between">
              <span className="bg-info p-2 rounded me-2 w-50 text-center">
                First Name
              </span>{" "}
              {info?.firstName}
            </li>
            <li className="list-group-item d-flex justify-content-between">
              <span className="bg-info p-2 rounded me-2 w-50 text-center">
                Last Name
              </span>
              {info?.lastName}
            </li>
            <li className="list-group-item d-flex justify-content-between">
              <span className="bg-info p-2 rounded me-2 w-50 text-center">
                Phone Number
              </span>
              {info?.phoneNumber}
            </li>
            <li className="list-group-item d-flex justify-content-between text-wrap text-break">
              <span className="bg-info p-2 rounded me-2 w-50 text-center">
                Email
              </span>
              {data?.email}
            </li>
            <li className="list-group-item d-flex justify-content-between">
              <span className="bg-info p-2 rounded me-2 w-50 text-center">
                Street Address
              </span>
              {info?.streetAddress}
            </li>
            <li className="list-group-item d-flex justify-content-between">
              <span className="bg-info p-2 rounded me-2 w-50 text-center">
                City
              </span>
              {info?.city}
            </li>
            <li className="list-group-item d-flex justify-content-between">
              <span className="bg-info p-2 rounded me-2 w-50 text-center">
                State
              </span>
              {info?.state}
            </li>
            <li className="list-group-item d-flex justify-content-between">
              <span className="bg-info p-2 rounded me-2 w-50 text-center">
                Zip Code
              </span>
              {info?.zipCode}
            </li>
          </ul>
        </Container>

        <Form onSubmit={handleSubmit}>
          <FormGroup controlId="formDescription" className="mb-2">
            <FormLabel>Description</FormLabel>
            <FormControl
              as={"textarea"}
              placeholder="Description"
              onChange={handleChange}
              value={data.description}
              name="description"
              rows={4}
            />
          </FormGroup>
          <FormGroup controlId="formNotes" className="mb-2">
            <FormLabel>Notes</FormLabel>
            <FormControl
              as={"textarea"}
              placeholder="Notes"
              onChange={handleChange}
              value={data.notes}
              name="notes"
              rows={4}
            />
          </FormGroup>

          <FormGroup controlId="formIsCompleted" className="mb-2">
            <Form.Check
              type="checkbox"
              label="isCompleted"
              onChange={handleChange}
              checked={data.isCompleted}
              name="isCompleted"
            />
          </FormGroup>
          <FormGroup controlId="formIsPaid" className="mb-2">
            <Form.Check
              type="checkbox"
              label="isPaid"
              onChange={handleChange}
              checked={data.isPaid}
              name="isPaid"
            />
          </FormGroup>
          <FormGroup controlId="formIsRemote" className="mb-2">
            <Form.Check
              type="checkbox"
              label="isRemote"
              checked={data.isRemote}
              onChange={handleChange}
              name="isRemote"
            />
          </FormGroup>
          <FormGroup controlId="formAmount" className="mb-2">
            <FormLabel>Amount</FormLabel>
            <FormControl
              type="number"
              placeholder="Amount"
              value={data.amount}
              onChange={handleChange}
              name="amount"
            />
          </FormGroup>
          <Button variant="primary" type="submit">
            Submit
          </Button>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
