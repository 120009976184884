import React, { useState } from "react";
import logo from "../images/elder-logo.jpg";
import { Link } from "react-router-dom";
import { useAuthContext } from "./AuthContext"; // Import the custom hook
import { Container, Nav, Navbar } from "react-bootstrap";
import {
  MdAccountCircle,
  MdOutlineMiscellaneousServices,
} from "react-icons/md";
import { FaHome } from "react-icons/fa";
import { BiLogIn, BiLogOut } from "react-icons/bi";
import { IoCreateOutline } from "react-icons/io5";
import { RiAdminLine } from "react-icons/ri";
import "../index.css";
import { GiHamburgerMenu } from "react-icons/gi";
import { RiSecurePaymentLine } from "react-icons/ri";
import { MdOutlineSupportAgent } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import ErrorNotification from "./ErrorNotification";
import { MdSecurity } from "react-icons/md";
import logError from "../functions/logError";

const styles = {
  brand: {
    fontFamily: "Computer",
    maxWidth: "55%",
  },
  icon20: { fontSize: 20 },
  icon22: { fontSize: 22 },
};

export default function NavbarComponent() {
  const navigate = useNavigate();
  const { currentUser, logout, currentUserData } = useAuthContext();
  const [expanded, setExpanded] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null); // This will be used to display error messages to the User

  // Define a function to toggle the expanded state
  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  // Define a function to collapse the navbar when an item is selected
  const collapseNavbar = () => {
    setExpanded(false);
  };

  return (
    <>
      <Navbar
        expand="lg"
        className="navbar-dark fixed-top bg-primary py-0" //navbar-dark bg-primary
        expanded={expanded}
        collapseOnSelect
      >
        <Container fluid>
          <Navbar.Brand
            as={Link}
            to={"/"}
            style={styles.brand}
            className="fs-3 d-flex align-items-center text-nowrap"
            onClick={collapseNavbar}
          >
            <img
              src={logo}
              alt="Logo"
              width="33"
              height="33"
              className="rounded-circle me-2"
            />
            Simple Tech Care
          </Navbar.Brand>

          <Navbar.Toggle
            aria-controls="navbarCollapse"
            onClick={toggleExpanded}
          >
            <GiHamburgerMenu style={styles.icon22} />
          </Navbar.Toggle>

          <Navbar.Collapse id="navbarCollapse">
            <Nav className="ms-auto">
              <Nav.Link
                as={Link}
                to="/services"
                role="button"
                active
                onClick={collapseNavbar}
                className="btn btn-light ms-2 mb-1 text-dark d-flex align-items-center justify-content-center"
              >
                Services{" "}
                <MdOutlineMiscellaneousServices style={styles.icon20} />
              </Nav.Link>
              <Nav.Link
                className="btn btn-light ms-2 mb-1 d-flex align-items-center justify-content-center text-dark"
                as={Link}
                to="/securified"
                role="button"
                active
                onClick={collapseNavbar}
              >
                Securified <MdSecurity style={styles.icon22} className="ms-1" />
              </Nav.Link>
              <Nav.Link
                className="btn btn-light ms-2 mb-1 d-flex align-items-center justify-content-center text-dark"
                as={Link}
                to="/remotesupport"
                role="button"
                active
                onClick={collapseNavbar}
              >
                Remote
                <MdOutlineSupportAgent style={styles.icon22} className="ms-1" />
              </Nav.Link>

              {!currentUser?.emailVerified ? (
                <>      
                  <Nav.Link
                    className="btn btn-light ms-2 mb-1 text-dark d-flex align-items-center justify-content-center"
                    as={Link}
                    to="/signup"
                    active
                    onClick={collapseNavbar}
                  >
                    Register
                    <IoCreateOutline style={styles.icon20} />
                  </Nav.Link>
                  <Nav.Link
                    className="btn btn-info ms-2 mb-1 d-flex align-items-center justify-content-center text-dark"
                    as={Link}
                    to="/login"
                    role="button"
                    active
                    onClick={collapseNavbar}
                  >
                    Login <BiLogIn style={styles.icon22} />
                  </Nav.Link>
                </>
              ) : (
                <>
                  <Nav.Link
                    className="btn btn-light ms-2 mb-1 text-dark d-flex align-items-center justify-content-center"
                    as={Link}
                    to={"/account"}
                    active
                    onClick={collapseNavbar}
                  >
                    Account
                    <MdAccountCircle style={styles.icon22} className="ms-1" />
                  </Nav.Link>
                  <Nav.Link
                    className="btn btn-success ms-2 mb-1 d-flex align-items-center justify-content-center text-light"
                    as={Link}
                    to="/home"
                    active
                    onClick={collapseNavbar}
                  >
                    Home
                    <FaHome style={styles.icon20} className="ms-1" />
                  </Nav.Link>
                  
                  {currentUserData?.isAdmin === true ? (
                    <>
                      <Nav.Link
                        className="btn btn-warning ms-2 mb-1 d-flex align-items-center justify-content-center text-dark"
                        as={Link}
                        to={"/admin"}
                        active
                        onClick={collapseNavbar}
                      >
                        Admin
                        <RiAdminLine style={styles.icon22} />
                      </Nav.Link>
                      <Nav.Link
                        className="btn btn-danger ms-2 mb-1 d-flex align-items-center justify-content-center text-light"
                        as={Link}
                        to={"/adminpayment"}
                        active
                        onClick={collapseNavbar}
                      >
                        Payment
                        <RiSecurePaymentLine style={styles.icon22} />
                      </Nav.Link>
                    </>
                  ) : null}
                  <Nav.Link
                    className="btn btn-info ms-2 mb-1 d-flex align-items-center justify-content-center text-dark"
                    as={Link}
                    to="/"
                    onClick={async (event) => {
                      try {
                        await logout();
                      } catch (error: any) {
                        setErrorMessage("An error occurred while logging out.");
                        logError(
                          currentUser ? currentUser?.uid : "No user id",
                          `Error: ${error.name}, Message: ${error.message}, Stack: ${error.stack}`,
                          "No appointment data",
                          "NavbarComponent.tsx"
                        ).then((result) => {
                          console.log("Error logged to database");
                        });
                      } finally {
                        navigate("/login"); // Redirect to the login page
                      }
                      collapseNavbar();
                    }}
                    active
                  >
                    Logout
                    <BiLogOut style={styles.icon22} />
                  </Nav.Link>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {errorMessage ? (
        <ErrorNotification
          message={errorMessage}
          timeLength={5000}
          setErrorMessage={setErrorMessage}
        />
      ) : null}
    </>
  );
}
